import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, ContentBlockProps, MarkdownTip } from 'shared/components';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

type HowToProps = {
  header?: string;
};

export const HowTo: FC<HowToProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'How To'} name={['howToSection']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isSectionVisible = getFieldValue(['howToSection', 'visible']);
          return (
            <>
              <MarkdownTip>
                <Form.Item
                  name={['howToSection', 'description']}
                  rules={[
                    ...(isSectionVisible
                      ? [
                          {
                            max: 255,
                            message: 'Section Subtitle should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="Section Subtitle" />
                </Form.Item>
              </MarkdownTip>
              <ContentBlockContainer
                maxItems={10}
                name={['howToSection', 'steps']}
                title="Step"
                addButtonText="Add Step"
                showChangePositionButton={true}
              >
                {props => <HowToBlock {...props} required={isSectionVisible} />}
              </ContentBlockContainer>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};

const HowToBlock: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  return (
    <>
      <Form.Item
        {...fieldProps}
        name={[name, 'stepTitle']}
        rules={[
          {
            required,
            message: 'Title is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  max: 255,
                  message: 'Title should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Title" />
      </Form.Item>
      <MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[name, 'stepDescription']}
          rules={[
            {
              required,
              message: 'Description is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    max: 5000,
                    message: 'Description should be at most 5000 characters',
                  },
                ]
              : []),
          ]}
        >
          <DebouncedInputWrapper element={Textarea} label="Description" />
        </Form.Item>
      </MarkdownTip>
    </>
  );
};
