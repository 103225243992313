import type { FC } from 'react';

import { Form, Input, Option, Select } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { LocationTabsProps } from '../LocationTabs/model';

import styles from './InsuranceCategories.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const InsuranceCategories: FC<
  Pick<LocationTabsProps, 'categories' | 'selectedInsuranceCategories' | 'header'>
> = ({ categories = [], selectedInsuranceCategories = [], header }) => {
  return (
    <CollapsiblePanel
      header={header || 'American Express Insurance Categories'}
      name={['americanExpressInsurance']}
      showSectionTitle
    >
      <div className={styles.content}>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue(['americanExpressInsurance', 'visible']);
            return (
              <Form.Item
                name={['americanExpressInsurance', 'description']}
                rules={[
                  ...(isFieldRequired
                    ? [
                        {
                          max: 255,
                          message: 'Subtitle should be at most 255 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <DebouncedInputWrapper element={Input} label="Subtitle" />
              </Form.Item>
            );
          }}
        </Form.Item>
        <ContentBlockContainer
          maxItems={10}
          addButtonText="Add category"
          name={['americanExpressInsurance', 'categories']}
          title="Category"
          showChangePositionButton={true}
        >
          {({ name, required, ...rest }) => (
            <Form.Item
              name={[name, 'categoryId']}
              rules={[
                {
                  required,
                  message: 'Category is required',
                },
              ]}
              {...rest}
            >
              <Select placeholder="Category">
                {categories?.map(item => (
                  <Option
                    key={item.id}
                    data-key={item.id}
                    selected
                    value={item.id}
                    disabled={selectedInsuranceCategories.some(categoryId => categoryId === item.id)}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </ContentBlockContainer>
      </div>
    </CollapsiblePanel>
  );
};
