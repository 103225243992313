import { Form, Input, Switch } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, ContentBlockProps, IconsGallery } from 'shared/components';

import BenefitsBlock from './BenefitBlock';

import styles from './OurPlansBlock.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export default function OurPlansBlock({ required, ...fieldProps }: ContentBlockProps) {
  const blockName = fieldProps.name;
  return (
    <div>
      <Form.Item
        name={[blockName, 'planName']}
        rules={[
          {
            required,
            message: 'Plan name is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  min: 1,
                  message: 'Plan name should be at least 1 character',
                },
                {
                  max: 255,
                  message: 'Plan name should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Plan Name" />
      </Form.Item>
      <Form.Item
        name={[blockName, 'priceTitle']}
        rules={
          required
            ? [
                {
                  min: 1,
                  message: 'Plan title should be at least 1 character',
                },
                {
                  max: 255,
                  message: 'Plan Title should be at most 255 characters',
                },
              ]
            : []
        }
      >
        <DebouncedInputWrapper element={Input} label="Price Title" />
      </Form.Item>
      <div className={styles.priceRow}>
        <Form.Item
          className={styles.priceItem}
          name={[blockName, 'price']}
          rules={[
            {
              required,
              message: 'Price is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    min: 1,
                    message: 'Price should be at least 1 character',
                  },
                  {
                    max: 255,
                    message: 'Price should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <DebouncedInputWrapper element={Input} label="Price" />
        </Form.Item>
        <Form.Item
          className={styles.priceSubtitleItem}
          name={[blockName, 'priceSubtitle']}
          rules={
            required
              ? [
                  {
                    min: 1,
                    message: 'Price subtitle should be at least 1 character',
                  },
                  {
                    max: 255,
                    message: 'Price subtitle should be at most 255 characters',
                  },
                ]
              : []
          }
        >
          <DebouncedInputWrapper element={Input} label="Price subtitle" />
        </Form.Item>
      </div>
      <div className={styles.toggleWrapper}>
        <p className={styles.label}>Promo Text</p>
        <Form.Item
          className={styles.switchItem}
          name={[blockName, 'promoText', 'visible']}
          valuePropName="checked"
          label="Visiable Section"
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </div>

      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isPromoTextVisible = getFieldValue([...fieldProps.parentName, blockName, 'promoText', 'visible']);
          return (
            <Form.Item
              name={[blockName, 'promoText', 'promoMessage']}
              rules={[
                {
                  required: required && isPromoTextVisible,
                  message: 'Promo message is required',
                  whitespace: true,
                },
                ...(required && isPromoTextVisible
                  ? [
                      {
                        min: 1,
                        message: 'Promo message should be at least 1 character',
                      },
                      {
                        max: 255,
                        message: 'Promo message should be at most 255 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <DebouncedInputWrapper element={Input} label="Promo message" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item preserve={false} valuePropName="icon" name={[blockName, 'promoText', 'thumbnailId']}>
        <IconsGallery />
      </Form.Item>
      <CollapsiblePanel withoutSwitch header="Benefits" name={[blockName, 'benefits']}>
        <div className={styles.paddedContent}>
          <ContentBlockContainer
            name={[blockName, 'benefits']}
            maxItems={10}
            className={styles.content}
            title="Benefit"
            addButtonText="Add Benefit"
            showChangePositionButton={true}
            isCollapsible
          >
            {props => <BenefitsBlock {...props} required={required} />}
          </ContentBlockContainer>
        </div>
      </CollapsiblePanel>
    </div>
  );
}
