import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { ContactContentBlock } from './components/ContactContentBlock/ContactContentBlock';

import styles from './ContactsContent.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const ContactContent = ({ header }: { header: string }) => {
  return (
    <CollapsiblePanel header={header || 'Still Have Questions?'} name={['contactContent']} showSectionTitle>
      <Form.Item
        name={['contactContent', 'subtitle']}
        rules={[
          {
            max: 255,
            message: 'Section Subtitle should be at most 255 characters',
          },
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Section Subtitle" />
      </Form.Item>
      <ContentBlockContainer
        className={styles.content}
        maxItems={5}
        addButtonText="Add contact"
        title="Contact"
        name={['contactContent', 'contacts']}
      >
        {props => <ContactContentBlock {...props} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
