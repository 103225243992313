import { useNavigate, useLocation } from 'react-router-dom';

export const useLocationValidation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const validateLocationId = (dataLocationId: string, adminLocationId: string) => {
    const [, locationInPath, lastUrl] = location.pathname.split('/');
    if (dataLocationId !== adminLocationId) {
      navigate(`/${locationInPath}/${lastUrl}`, { replace: true });
    }
  };

  return { validateLocationId };
};
