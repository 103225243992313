import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { IconsGallery, MarkdownTip, Tip } from 'shared/components';

import styles from '../../ContactsContent.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const ContactContentBlock: FC<ContentBlockProps> = ({ name, ...fieldProps }) => {
  return (
    <>
      <div className={styles.inputsWrapper}>
        <Tip>
          If any of the fields in the contacts section are left blank, then they will not be shown on the public
          website.
        </Tip>
        <Form.Item
          {...fieldProps}
          name={[name, 'subtitle']}
          rules={[
            {
              max: 255,
              message: 'Contact Subtitle should be at most 255 characters',
            },
          ]}
        >
          <DebouncedInputWrapper element={Input} label="Contact Subtitle" />
        </Form.Item>
        <div className={styles.contactsWrapper}>
          <Form.Item
            {...fieldProps}
            name={[name, 'email']}
            normalize={(value: string) => value?.trim()}
            rules={[
              {
                type: 'email',
                message: 'Email is not valid',
              },
              {
                max: 100,
                message: 'Email should be at most 100 characters.',
              },
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Email (optional)" />
          </Form.Item>
          <Form.Item {...fieldProps} name={[name, 'phoneNumber']}>
            <DebouncedInputWrapper element={Input} label="Phone Number (optional)" />
          </Form.Item>
        </div>
        <MarkdownTip>
          <Form.Item
            {...fieldProps}
            name={[name, 'schedule']}
            rules={[
              {
                max: 1000,
                message: 'Schedule Note should be at most 1000 characters',
              },
            ]}
          >
            <DebouncedInputWrapper element={Textarea} label="Note (Optional)" />
          </Form.Item>
        </MarkdownTip>
        <Form.Item {...fieldProps} preserve={false} valuePropName="icon" name={[name, 'thumbnailId']}>
          <IconsGallery />
        </Form.Item>
      </div>
    </>
  );
};
