import type { FC } from 'react';

import { Form, Icon, Input, Switch, Textarea, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel, SubTitle } from 'shared/components';

import styles from './InformationAboutInsurancePartner.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

const InformationAboutInsurancePartner: FC = () => {
  return (
    <CollapsiblePanel header="Information about Insurance Partner" name={['insurancePartnerInformation']} withoutSwitch>
      <div className={styles.content}>
        <SubTitle>Display a section within the landing pages for Partner Products?</SubTitle>
        <div className={styles.toggleWrapper}>
          <Form.Item name={['insuranceProviderSection', 'visible']} valuePropName="checked">
            <Switch checkedChildren="on" unCheckedChildren="off" />
          </Form.Item>
          <label htmlFor="maintenance_toggle">
            <Tooltip
              title={
                'By activating this feature, the system will showcase the additional section on the Product Landing pages for the modified insurance provider.'
              }
            >
              <Icon size={25} name="question-mark-circle-outline" />
            </Tooltip>
          </label>
        </div>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isSectionVisible = getFieldValue(['insuranceProviderSection', 'visible']);
            return (
              <>
                <SubTitle>Section Content</SubTitle>
                <Form.Item
                  name={['insuranceProviderSection', 'sectionTitle']}
                  rules={[
                    {
                      required: isSectionVisible,
                      message: 'Title is required',
                      whitespace: true,
                    },
                    ...(isSectionVisible
                      ? [
                          {
                            max: 100,
                            message: 'Title should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Title" />
                </Form.Item>
                <Form.Item
                  name={['insuranceProviderSection', 'description']}
                  rules={[
                    {
                      required: isSectionVisible,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    ...(isSectionVisible
                      ? [
                          {
                            max: 800,
                            message: 'Partner description should be at most 800 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="Partner Description" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};

export default InformationAboutInsurancePartner;
