import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { ContactsBlock } from './components/ContactsBlock';
import { ContactsProps } from './model';

import styles from './Contacts.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const Contacts: FC<ContactsProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Still Have Questions?'} name={['contact']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['contact', 'visible']);
          return (
            <>
              <Form.Item
                name={['contact', 'subtitle']}
                rules={[
                  ...(isFieldRequired
                    ? [
                        {
                          max: 255,
                          message: 'Section Subtitle should be at most 255 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <DebouncedInputWrapper element={Input} label="Section Subtitle" />
              </Form.Item>
              <ContentBlockContainer
                className={styles.content}
                maxItems={5}
                addButtonText="Add contact"
                title="Contact"
                name={['contact', 'contacts']}
                showChangePositionButton={true}
              >
                {props => <ContactsBlock {...props} />}
              </ContentBlockContainer>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
