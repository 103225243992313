import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { BlockWithIcon, CollapsiblePanel, ContentBlockContainer, IconsGallery } from 'shared/components';

import { WhatIsCoveredProps } from './model';

import styles from './WhatIsCovered.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const WhatIsCovered: FC<WhatIsCoveredProps> = ({ header }) => {
  const sectionName = ['allYouNeedToKnow', 'subSections', 'whatIsCovered'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel
        header={header || 'What Is Covered'}
        sectionTitleLabel="Tab Title"
        name={sectionName}
        showSectionTitle
      >
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...sectionName, 'visible']);
            return (
              <>
                <Form.Item
                  preserve={false}
                  valuePropName="icon"
                  name={[...sectionName, 'thumbnailId']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            required: true,
                            message: 'Thumbnail is required',
                          },
                        ]
                      : []),
                  ]}
                >
                  <IconsGallery />
                </Form.Item>
                <Form.Item
                  name={[...sectionName, 'tabSubtitle']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            max: 100,
                            message: 'Tab Subtitle should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Tab Subtitle" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <ContentBlockContainer
          className={styles.content}
          name={[...sectionName, 'covered']}
          addButtonText="Add what is covered"
          title="What is covered"
          showChangePositionButton={true}
        >
          {props => <BlockWithIcon {...props} />}
        </ContentBlockContainer>
      </CollapsiblePanel>
    </div>
  );
};
