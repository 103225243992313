import cn from 'classnames';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { getLocationTimezone } from '@saturn/api';
import { DatePicker, Form, Icon, Input, RadioGroup, Switch, TimePicker, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel, IconsGallery, Tip } from 'shared/components';
import { useAdminLocation } from 'shared/utils';

import styles from './PromoEventsBanner.module.scss';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

const bannerColorOptions = [
  { value: 'yellow', label: 'Yellow' },
  { value: 'darkblue', label: 'Dark Blue' },
  { value: 'green', label: 'Green' },
];
export default function PromoEventsBanner() {
  const { adminLocation } = useAdminLocation();
  const locationTimezone = getLocationTimezone(adminLocation.id);

  const disabledStartDate = (date: Dayjs) => {
    const minDate = dayjs().startOf('day');
    const maxDate = dayjs().add(1, 'month').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  const disabledExpiryDates = (startDate: string) => (date: Dayjs) => {
    const start = startDate ? dayjs(startDate) : dayjs();
    const minDate = start.clone().add(1, 'day').startOf('day');
    const maxDate = minDate.clone().add(3, 'months').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  return (
    <CollapsiblePanel header="Promo Events Banner" name={['promoEventBanner']}>
      <Form.Item shouldUpdate>
        {({ getFieldValue, setFieldValue }) => {
          const isSectionVisible = getFieldValue(['promoEventBanner', 'visible']);
          const callToActionButtonVisible = getFieldValue(['promoEventBanner', 'callToActionButton', 'visible']);
          const displayNow = getFieldValue(['promoEventBanner', 'displayNow']);
          const noExpiry = getFieldValue(['promoEventBanner', 'noExpiry']);
          const startDate = getFieldValue(['promoEventBanner', 'bannerStartDate']);
          const expiryDate = getFieldValue(['promoEventBanner', 'bannerExpiryDate']);

          return (
            <>
              <p className={styles.label}>Banner Color</p>
              <Form.Item
                name={['promoEventBanner', 'bannerColor']}
                initialValue="yellow"
                rules={[
                  {
                    required: true,
                    message: 'Banner color is required',
                  },
                ]}
              >
                <RadioGroup options={bannerColorOptions} />
              </Form.Item>
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>Show now?</p>
                <Form.Item name={['promoEventBanner', 'displayNow']} valuePropName="checked">
                  <Switch checkedChildren="on" unCheckedChildren="off" />
                </Form.Item>
                <Tooltip title="Enable to display a promo banner immediately after saving changes.">
                  <Icon size={25} name="question-mark-circle-outline" />
                </Tooltip>
              </div>
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>No expiry date?</p>
                <Form.Item name={['promoEventBanner', 'noExpiry']} valuePropName="checked">
                  <Switch checkedChildren="on" unCheckedChildren="off" />
                </Form.Item>
                <Tooltip title="Banner will persist until manually turned off, with no automatic expiration.">
                  <Icon size={25} name="question-mark-circle-outline" />
                </Tooltip>
              </div>
              <div className={styles.dateTimeBlock}>
                {!displayNow && (
                  <>
                    <Form.Item
                      name={['promoEventBanner', 'bannerStartDate']}
                      normalize={date => {
                        return date && dayjs(date).startOf('day').format('YYYY-MM-DD');
                      }}
                      rules={[
                        { required: isSectionVisible && !displayNow, message: 'Banner display start date is required' },
                      ]}
                    >
                      <DatePicker
                        onChange={() => {
                          if (expiryDate) {
                            setFieldValue(['promoEventBanner', 'bannerExpiryDate'], null);
                          }
                        }}
                        label="Banner Display Start Date"
                        showNow={true}
                        disabledDate={disabledStartDate}
                        suffixIcon={<Icon name="calendar-outline" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['promoEventBanner', 'bannerStartTime']}
                      normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                      rules={[
                        { required: isSectionVisible && !displayNow, message: 'Banner display start time is required' },
                      ]}
                    >
                      <TimePicker
                        timezone={locationTimezone}
                        label="Banner Display Start Time"
                        inputReadOnly
                        allowClear
                        showNow={false}
                      />
                    </Form.Item>
                  </>
                )}
                {!noExpiry && (
                  <>
                    <Form.Item
                      name={['promoEventBanner', 'bannerExpiryDate']}
                      normalize={date => {
                        return date && dayjs(date).startOf('day').format('YYYY-MM-DD');
                      }}
                      rules={[{ required: isSectionVisible && !noExpiry, message: 'Banner expiry date is required' }]}
                    >
                      <DatePicker
                        disabled={!displayNow && !startDate}
                        label="Banner Expiry Date"
                        disabledDate={disabledExpiryDates(startDate)}
                        suffixIcon={<Icon name="calendar-outline" />}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['promoEventBanner', 'bannerExpiryTime']}
                      normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                      rules={[{ required: isSectionVisible && !noExpiry, message: 'Banner expiry time is required' }]}
                    >
                      <TimePicker
                        showNow={false}
                        timezone={locationTimezone}
                        label="Banner Expiry Time"
                        inputReadOnly
                        allowClear
                      />
                    </Form.Item>
                  </>
                )}
              </div>
              {displayNow && noExpiry ? null : (
                <Tip>
                  Please take into account that the time should be adjusted for the UTC
                  {locationTimezone}({adminLocation.name}) timezone.
                </Tip>
              )}

              {!noExpiry && (
                <div className={cn(styles.toggleWrapper, styles.displayCountdownBlock)}>
                  <p className={styles.label}>Display Countdown Timer in the banner?</p>
                  <Form.Item name={['promoEventBanner', 'displayCountdownTimer']} valuePropName="checked">
                    <Switch checkedChildren="on" unCheckedChildren="off" />
                  </Form.Item>
                  <Tooltip title="Include a countdown timer in the banner to convey time sensitivity and urgency to customers.">
                    <Icon size={25} name="question-mark-circle-outline" />
                  </Tooltip>
                </div>
              )}
              <Form.Item
                name={['promoEventBanner', 'bannerTitle']}
                rules={[
                  {
                    required: isSectionVisible,
                    message: 'Banner title is required',
                    whitespace: true,
                  },
                  {
                    min: 1,
                    message: 'Banner title should be at min 1 character',
                  },
                  {
                    max: 255,
                    message: 'Banner title should be at most 255 characters',
                  },
                ]}
              >
                <DebouncedInputWrapper element={Input} label="Banner title" />
              </Form.Item>
              <Form.Item preserve={false} valuePropName="icon" name={['promoEventBanner', 'thumbnailId']}>
                <IconsGallery />
              </Form.Item>
              <div className={styles.callToActionHeader}>
                <p>Call to Action Button</p>
                <Form.Item
                  name={['promoEventBanner', 'callToActionButton', 'visible']}
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch checkedChildren="on" unCheckedChildren="off" />
                </Form.Item>
              </div>
              <Form.Item
                name={['promoEventBanner', 'callToActionButton', 'title']}
                rules={
                  isSectionVisible && callToActionButtonVisible
                    ? [
                        {
                          required: true,
                          message: 'Action button title is required',
                          whitespace: true,
                        },
                        {
                          min: 1,
                          message: 'Action button title should be at min 1 character',
                        },
                        {
                          max: 75,
                          message: 'Action button title should be at most 75 characters',
                        },
                      ]
                    : undefined
                }
              >
                <DebouncedInputWrapper element={Input} label="Title" />
              </Form.Item>
              <Form.Item
                name={['promoEventBanner', 'callToActionButton', 'url']}
                rules={
                  isSectionVisible && callToActionButtonVisible
                    ? [
                        {
                          required: true,
                          message: 'URL is required',
                          whitespace: true,
                        },
                        {
                          type: 'url',
                          message: 'URL field is invalid',
                        },
                        {
                          min: 1,
                          message: 'URL should be at min 1 character',
                        },
                        {
                          max: 255,
                          message: 'URL should be at most 255 characters',
                        },
                      ]
                    : undefined
                }
              >
                <DebouncedInputWrapper element={Input} label="URL" />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
}
