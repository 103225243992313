import { ContentBlockProps, IconsGallery } from 'shared/components';
import { Form, Input } from '@saturn/uikit';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export default function QuoteNowProductBenefitBlock({ required, ...fieldProps }: ContentBlockProps) {
  const blockName = fieldProps.name;
  return (
    <div>
      <Form.Item
        name={[blockName, 'title']}
        rules={[
          {
            required,
            message: 'Title is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  min: 1,
                  message: 'Title should be at least 1 character',
                },
                {
                  max: 255,
                  message: 'Title should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Title" />
      </Form.Item>
      <Form.Item
        name={[blockName, 'tooltip']}
        rules={[
          {
            required,
            message: 'Tooltip is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  min: 1,
                  message: 'Tooltip should be at least 1 character',
                },
                {
                  max: 500,
                  message: 'Tooltip should be at most 500 characters',
                },
              ]
            : []),
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Tooltip" />
      </Form.Item>
      <Form.Item
        {...fieldProps}
        preserve={false}
        valuePropName="icon"
        name={[blockName, 'thumbnailId']}
        rules={[
          {
            required: required,
            message: 'Thumbnail is required',
          },
        ]}
      >
        <IconsGallery />
      </Form.Item>
    </div>
  );
}
