import type { FC } from 'react';

import { ScrollableSection } from 'types/scrollableSection';

import { Form, Icon, Option, Select } from '@saturn/uikit';

import { ContentBlockContainer } from 'shared/components';

import styles from './PageSummaryBlock.module.scss';
import { Alert } from 'antd';

interface PageSummaryBlockProps {
  name: string[];
  maxItems: number;
  addButtonText?: string;
  scrollableSections?: Record<string, ScrollableSection>;
}

export const PageSummaryBlock: FC<PageSummaryBlockProps> = ({
  name,
  maxItems,
  addButtonText = 'Add section',
  scrollableSections,
}) => {
  return (
    <div className={styles.content}>
      <Alert
        showIcon
        icon={<Icon color="#f48809" name="alert-circle-outline" />}
        type="warning"
        message="The link is not displayed if related section is hidden!"
        style={{ marginBottom: '20px' }}
      />
      <ContentBlockContainer
        maxItems={maxItems}
        title="Section"
        addButtonText={addButtonText}
        name={name}
        layout="horizontal"
      >
        {({ name: blockName, required, ...rest }) => {
          return (
            <>
              <Form.Item
                name={[blockName, 'sectionId']}
                rules={[
                  {
                    required,
                    message: 'Section is required',
                  },
                ]}
                {...rest}
              >
                <Select placeholder="Section">
                  {Object.values(scrollableSections ?? {})
                    .filter(
                      section =>
                        ![
                          'pageSummary',
                          'planLegalDisclaimer',
                          'highlightPlan',
                          'promotionBenefit',
                          'referFriend',
                          'nextSteps',
                        ].includes(section.sectionId),
                    )
                    ?.map(item => (
                      <Option key={item.sectionId} data-key={item.sectionId} value={item.sectionId}>
                        {item.sectionName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          );
        }}
      </ContentBlockContainer>
    </div>
  );
};
