import { DebounceInput } from 'react-debounce-input';
import type { DebounceInputProps } from 'react-debounce-input';
import { Input, Textarea } from '@saturn/uikit';

type DebounceInputTypes = typeof Textarea | typeof Input;

/* eslint-disable  @typescript-eslint/no-explicit-any */
interface DebouncedInputProps<T extends DebounceInputTypes> extends Omit<DebounceInputProps<T, any>, 'element'> {
  element: T;
}

export const DebouncedInputWrapper = <T extends DebounceInputTypes>({ element, ...props }: DebouncedInputProps<T>) => {
  // @ts-expect-error
  return <DebounceInput element={element} {...props} />;
};
