import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { FindOutMoreBlock } from './components/FindOutMoreBlock';
import styles from './FindOutMore.module.scss';

type FindOutMoreProps = {
  name: string;
  header?: string;
};

export const FindOutMore: FC<FindOutMoreProps> = ({ name, header }) => {
  return (
    <CollapsiblePanel header={header || 'Find Out More'} name={[name]} showSectionTitle>
      <ContentBlockContainer
        className={styles.content}
        name={[name, 'blogs']}
        addButtonText="Add Content"
        title={'Find Out More'}
        showChangePositionButton={true}
      >
        {fieldsInfo => <FindOutMoreBlock {...fieldsInfo} />}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
};
