import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';
import type { RuleType } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import { VideoProps } from './models';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

export const Video: FC<VideoProps> = ({ header, parent }) => {
  return (
    <CollapsiblePanel
      header={header || (parent === 'location' || parent === 'category' ? 'Marketing video' : 'Video')}
      showSectionTitle={parent === 'location' || parent === 'category'}
      sectionTitleName="title"
      isRequiredSectionTitle
      name={['video']}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['video', 'visible']);
          return (
            <>
              {(parent === 'location' || parent === 'category') && (
                <Form.Item
                  name={['video', 'subtitle']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            max: 255,
                            message: 'Subtitle should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Subtitle" />
                </Form.Item>
              )}
              <Form.Item
                name={['video', 'url']}
                rules={[
                  {
                    required: isFieldRequired,
                    message: 'The "Video link" field is required',
                    whitespace: true,
                  },
                  ...(isFieldRequired
                    ? [
                        {
                          type: 'url' as RuleType,
                          message: 'The "Video link" field is invalid',
                        },
                        {
                          pattern: new RegExp(
                            '^(https?:\\/\\/)?(www\\.youtube\\.com|youtu\\.be|cf\\.cdn\\.uplynk\\.com)\\/.+$',
                          ),
                          message: 'The "Video link" is not a valid YouTube or LimeLight link',
                        },
                        {
                          max: 2049,
                          message: 'The "Video link" field should be at most 2049 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <DebouncedInputWrapper element={Input} type="url" label="Video link" />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
