import { FC, ReactNode, useCallback } from 'react';

import { Popconfirm, message } from 'antd';
import cn from 'classnames';
import noop from 'lodash/noop';

import { Button, Icon } from '@saturn/uikit';
import { ComponentProps } from '@saturn/uikit/src/models/components';

import { CollapsiblePanel } from '../../../CollapsiblePanel/CollapsiblePanel';

import styles from './CollapsibleContentBlock.module.scss';

interface CollapsibleContentBlockInnerProps extends ComponentProps {
  index: number;
  name?: Array<number | string>;
  title: string;
  onDelete?: () => void;
  children?: ReactNode;
  hideDeleteButton?: boolean;
}

export const CollapsibleContentBlock: FC<CollapsibleContentBlockInnerProps> = ({
  style,
  name,
  title,
  index,
  onDelete,
  children,
  className,
  hideDeleteButton,
}) => {
  const handleDelete = useCallback(() => {
    onDelete?.();
    message.info('Block has been successfully deleted');
  }, [onDelete]);

  return (
    <div className={cn(className, 'saturn-content-block', styles.wrapper)} style={style}>
      <CollapsiblePanel
        withoutSwitch
        header={
          <div className={styles.collapsibleBlockHeader}>
            {`${title} ${index !== undefined ? index + 1 : ''}`}
            {onDelete && !hideDeleteButton ? (
              <Popconfirm
                placement="top"
                title="Are you sure you want to delete block?"
                onConfirm={handleDelete}
                okText="Delete"
                cancelText="Cancel"
                onCancel={e => e?.stopPropagation()}
              >
                <div
                  className={styles.deleteBlockButton}
                  role="button"
                  tabIndex={0}
                  onKeyDown={noop}
                  onClick={e => e.stopPropagation()}
                >
                  <Button data-key={`${name?.join('-')}-delete-block-btn`}>
                    <Icon name="trash-2-outline" />
                    Delete Block
                  </Button>
                </div>
              </Popconfirm>
            ) : null}
          </div>
        }
      >
        <div className={styles.content}>{children}</div>
      </CollapsiblePanel>
    </div>
  );
};
