import { Form, Icon, Input, Tooltip } from '@saturn/uikit';

import styles from './EventSystemInfo.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export function EventSystemInfo(): JSX.Element {
  return (
    <div className={styles.content}>
      <Form.Item name="visible" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="systemName"
        rules={[
          {
            required: true,
            message: 'Name is required',
            whitespace: true,
          },
          {
            max: 100,
            message: 'Name should be at most 100 characters',
          },
          {
            pattern: /^[A-Za-z\d-\s]*$/,
            message: 'There are restricted symbols in the value',
          },
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Name" />
      </Form.Item>
      <Form.Item
        name="slugPart"
        rules={[
          { required: true, message: 'Slug is required' },
          { max: 40, message: 'Slug should be at most 40 characters' },
          { min: 3, message: 'Slug should be at least 3 characters' },
          {
            pattern: /^[a-z\d-]*$/,
            message: 'There are restricted symbols in the value',
          },
        ]}
      >
        <DebouncedInputWrapper
          element={Input}
          label="Slug"
          addonAfter={
            <Tooltip
              title={
                'SLUG is the last part of url after the last "/". Please note that the page of a product is not available by old url after change of slug. The value may contain Roman characters, digits, and dashes. Min-max size of the value is 3-40 chars. And it must be unique per location.'
              }
            >
              <Icon size={35} name="question-mark-circle-outline" />
            </Tooltip>
          }
        />
      </Form.Item>
    </div>
  );
}
