import { useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';

import cn from 'classnames';
import debounce from 'lodash/debounce';
import useEditCoveragesStore, { EditCoveragesState } from 'store/useEditCoveragesStore';

import { Button, Form, Icon, Input, Textarea, notification } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlock, IconsGallery, MarkdownTip } from 'shared/components';

import { CoveragesProps } from '../Coverages';

import styles from './EditCoverages.module.scss';
import type { InputRef } from 'antd';

const EditCoverages: FC<CoveragesProps> = ({ header }) => {
  const form = Form.useFormInstance();
  const visible = form.getFieldValue('allYouNeedToKnow')?.['subSections']?.['coverage']?.['visible'];
  const { coveragesState, coveragesError, setCoveragesState, setIsVisible } = useEditCoveragesStore();
  const sectionName = ['allYouNeedToKnow', 'subSections', 'coverage'];
  const coveragesSectionTitleInputRef = useRef<InputRef>(null);
  const coveragesTabSubtitleInputRef = useRef<InputRef>(null);

  const [rerenderAfterImportSectionTitle, setRerenderAfterImportSectionTitle] = useState(false);
  const [rerenderAfterImportTabSubtitle, setRerenderAfterImportTabSubtitle] = useState(false);

  const syncState = debounce((fieldUpdated?: string) => {
    // No need to watch for new sectios and thumbnail updates because their controls update zustand storage direclty
    // dcoument.getElementById logic is necessary because of 2 things:
    // 1. Keeping controls uncontrolled in sake of perfomance and UX (changing zustand storage directly is too heavy and slow operation for comfortable input's value update)
    // 2. Having uncontrolled controls with Antd components, which doesn't support refs well enough
    const sectionTitle =
      (document.getElementById('coverages_sectionTitle') as HTMLInputElement)?.value ?? coveragesState?.sectionTitle;
    const tabSubtitle =
      (document.getElementById('coverages_tabSubtitle') as HTMLInputElement)?.value ?? coveragesState?.tabSubtitle;
    const subSections = coveragesState?.subSections.map((subSection, sectionIndex) => {
      return {
        ...subSection,
        subtitle:
          (document.getElementById(`coverages_subSection_${sectionIndex}_subtitle`) as HTMLInputElement)?.value ??
          subSection.subtitle,
        coverage: subSection.coverage.map((subSectionCoverage, subSectionIndex) => {
          return {
            ...subSectionCoverage,
            title:
              (
                document.getElementById(
                  `coverages_subSection_${sectionIndex}_coverage_${subSectionIndex}_title`,
                ) as HTMLInputElement
              )?.value ?? subSectionCoverage?.title,
            titleTouched: fieldUpdated === subSectionCoverage.key ? true : (subSectionCoverage.titleTouched ?? false),
            description:
              (
                document.getElementById(
                  `coverages_subSection_${sectionIndex}_coverage_${subSectionIndex}_description`,
                ) as HTMLInputElement
              )?.value ?? subSectionCoverage?.description,
          };
        }),
      };
    });
    const newFormState = {
      ...coveragesState,
      sectionTitle,
      sectionTitleTouched: fieldUpdated === 'sectionTitle' ? true : (coveragesState?.sectionTitleTouched ?? false),
      tabSubtitle,
      subSections,
    } as EditCoveragesState;
    setCoveragesState(newFormState);
  }, 250);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible, setIsVisible]);

  const blocks = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () =>
      coveragesState?.subSections.map((subSection, sectionIndex, arr) => {
        return (
          <div key={subSection.key} className={styles.content}>
            <ContentBlock
              name={[...sectionName, 'subSections']}
              title="Coverage Subsection"
              index={sectionIndex}
              onDelete={() => {
                const newFormState = structuredClone(coveragesState);
                newFormState.subSections.splice(sectionIndex, 1);
                setCoveragesState(newFormState);
              }}
              onMove={(from, to) => {
                const newFormState = structuredClone(coveragesState);
                [newFormState.subSections[from], newFormState.subSections[to]] = [
                  newFormState.subSections[to],
                  newFormState.subSections[from],
                ];
                setCoveragesState(newFormState);
              }}
              toggleBlock={null}
              length={arr.length}
              showChangePositionButton={true}
            >
              <Input
                id={`coverages_subSection_${sectionIndex}_subtitle`}
                label="Section Subtitle"
                className={styles.coverageError}
                defaultValue={subSection.subtitle}
                customErrorMessage={coveragesError?.subSections[sectionIndex].subtitle}
                onKeyDown={() => {
                  syncState();
                }}
              />
              {subSection.coverage.map((subSectionCoverage, subSectionIndex, arr) => {
                return (
                  <ContentBlock
                    key={subSectionCoverage.key}
                    name={[...sectionName, 'subSections', 'coverage']}
                    title="Coverage"
                    index={subSectionIndex}
                    onDelete={() => {
                      const newFormState = structuredClone(coveragesState);
                      newFormState.subSections[sectionIndex].coverage.splice(subSectionIndex, 1);
                      setCoveragesState(newFormState);
                    }}
                    onMove={(from, to) => {
                      const newFormState = structuredClone(coveragesState);
                      [
                        newFormState.subSections[sectionIndex].coverage[from],
                        newFormState.subSections[sectionIndex].coverage[to],
                      ] = [
                        newFormState.subSections[sectionIndex].coverage[to],
                        newFormState.subSections[sectionIndex].coverage[from],
                      ];
                      setCoveragesState(newFormState);
                    }}
                    toggleBlock={null}
                    className={styles.subSectionCoverage}
                    length={arr.length}
                    showChangePositionButton={true}
                  >
                    <Input
                      id={`coverages_subSection_${sectionIndex}_coverage_${subSectionIndex}_title`}
                      label="Title"
                      className={styles.coverageError}
                      defaultValue={subSectionCoverage.title}
                      customErrorMessage={coveragesError?.subSections[sectionIndex].coverage[subSectionIndex].title}
                      onKeyDown={() => {
                        syncState(subSectionCoverage.key);
                      }}
                    />
                    <MarkdownTip className={styles.markdownTip}>
                      <Textarea
                        id={`coverages_subSection_${sectionIndex}_coverage_${subSectionIndex}_description`}
                        label="Description"
                        defaultValue={subSectionCoverage.description}
                        className={styles.coverageError}
                        customErrorMessage={
                          coveragesError?.subSections[sectionIndex].coverage[subSectionIndex].description
                        }
                        onKeyDown={() => {
                          syncState();
                        }}
                      />
                    </MarkdownTip>
                    <IconsGallery
                      icon={subSectionCoverage.thumbnailId}
                      customErrorMessage={
                        coveragesError?.subSections[sectionIndex].coverage[subSectionIndex].thumbnailId
                      }
                      onChange={src => {
                        if (src) {
                          const newFormState = structuredClone(coveragesState);
                          newFormState.subSections[sectionIndex].coverage[subSectionIndex].thumbnailId = src;
                          setCoveragesState(newFormState);
                        } else {
                          // No need for general form validations update. It's handled by the setInterval form update watcher
                          notification.error({
                            message: 'Thumbnail change failed. Icon source cannot be empty',
                          });
                        }
                      }}
                    />
                  </ContentBlock>
                );
              })}
            </ContentBlock>
            {coveragesState.subSections[sectionIndex].coverage.length < 20 && (
              <div className={styles.controlPanel}>
                <Button
                  data-key="edit-coverage-custom-add-block-btn"
                  type="primary"
                  onClick={() => {
                    const newFormState = structuredClone(coveragesState);
                    const newCoverage = [
                      ...subSection.coverage,
                      {
                        key: window.crypto.randomUUID(),
                        title: '',
                        titleTouched: false,
                        description: '',
                        thumbnailId: '',
                      },
                    ];
                    newFormState.subSections[sectionIndex].coverage = newCoverage;
                    setCoveragesState(newFormState);
                  }}
                >
                  <Icon name="plus-outline" />
                  <span>Add coverage</span>
                </Button>
              </div>
            )}
          </div>
        );
      }),
    [JSON.stringify(coveragesState), JSON.stringify(coveragesError)],
  );

  useEffect(() => {
    const sectionTitleInput = coveragesSectionTitleInputRef.current?.input as HTMLInputElement;
    const tabSubtitleInput = coveragesTabSubtitleInputRef.current?.input as HTMLInputElement;

    if (coveragesState?.sectionTitle !== sectionTitleInput?.value) {
      setRerenderAfterImportSectionTitle(prevState => !prevState);
    }

    if (coveragesState?.tabSubtitle !== tabSubtitleInput?.value) {
      setRerenderAfterImportTabSubtitle(prevState => !prevState);
    }
  }, [coveragesState?.sectionTitle, coveragesState?.tabSubtitle]);

  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel header={header || 'Coverages'} name={sectionName}>
        <Input
          forwardRef={coveragesSectionTitleInputRef}
          defaultValue={coveragesState?.sectionTitle}
          key={`coverages_sectionTitle-${String(rerenderAfterImportSectionTitle)}`}
          id="coverages_sectionTitle"
          label="Tab Title"
          className={styles.coverageError}
          customErrorMessage={coveragesError?.sectionTitle}
          onKeyDown={() => {
            syncState('sectionTitle');
          }}
        />
        <div className={styles.content}>
          <div className={styles.iconsGalleryWrapper}>
            <IconsGallery
              icon={coveragesState?.thumbnailId ?? undefined}
              customErrorMessage={coveragesError?.thumbnailId ?? undefined}
              onChange={src => {
                if (src) {
                  setCoveragesState({
                    ...coveragesState,
                    thumbnailId: src,
                  } as EditCoveragesState);
                } else {
                  notification.error({
                    message: 'Thumbnail change failed. Icon source cannot be empty',
                  });
                }
              }}
            />
          </div>
          <Input
            forwardRef={coveragesTabSubtitleInputRef}
            label="Tab Subtitle"
            key={`coveragesState_tabSubtitle-${String(rerenderAfterImportTabSubtitle)}`}
            id="coverages_tabSubtitle"
            className={styles.coverageError}
            defaultValue={coveragesState?.tabSubtitle}
            customErrorMessage={coveragesError?.tabSubtitle}
            onKeyDown={() => {
              syncState();
            }}
          />
          <div className={cn('saturn-content-block-container', styles.contentBlockWrapper)}>
            {blocks}
            {blocks && blocks?.length < 10 && (
              <div className={styles.controlPanel}>
                <Button
                  data-key="edit-subcoverage-custom-add-block-btn"
                  type="primary"
                  onClick={() => {
                    setCoveragesState({
                      ...coveragesState,
                      subSections: [
                        ...(coveragesState?.subSections ?? []),
                        {
                          subtitle: '',
                          key: window.crypto.randomUUID(),
                          coverage: [],
                        },
                      ],
                    } as EditCoveragesState);
                  }}
                >
                  <Icon name="plus-outline" />
                  <span>Add coverage subsection</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </CollapsiblePanel>
    </div>
  );
};

export default EditCoverages;
