import { notification } from 'antd';
import cn from 'classnames';

import { Form, Icon, ImageUploader, Input, Tooltip } from '@saturn/uikit';

import { SubTitle, Tip } from 'shared/components';
import { fileUrlFormatter } from 'shared/utils';

import styles from './InsurancePartnerSystemInfo.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export function InsurancePartnerSystemInfo(): JSX.Element {
  const beforeUpload = (file: File) => {
    const isLt2M = file.size / 1024 / 1024 <= 2;
    if (!/\.(svg)$/i.test(file.name)) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: 'Only files with these extensions are allowed: svg.',
      });
      return false;
    }
    if (!isLt2M) {
      notification.error({
        message: 'Picture has not been uploaded',
        description: 'Picture has to be smaller than 2MB.',
      });
      return false;
    }
    return true;
  };

  const customRequest = ({ onSuccess }: { onSuccess?: (body: string) => void }) => {
    onSuccess?.('ok');
  };

  return (
    <div className={styles.wrapper}>
      <Form.Item
        className={styles.insurancePartnerName}
        name="title"
        rules={[
          {
            required: true,
            message: 'Insurance Partner Name is required',
            whitespace: true,
          },
          {
            min: 3,
            message: 'Insurance Partner Name should be at least 3 characters',
          },
          {
            max: 255,
            message: 'Insurance Partner Name should be at most 255 characters',
          },
        ]}
      >
        <DebouncedInputWrapper
          element={Input}
          label="Insurance Partner Name"
          addonAfter={
            <Tooltip title="Name of the Insurance Partner that will be used in CMS.">
              <Icon size={35} name="question-mark-circle-outline" />
            </Tooltip>
          }
        />
      </Form.Item>
      <SubTitle>Insurance Partner Logo</SubTitle>
      <div className={styles.image_wrapper}>
        <Form.Item
          name="logoWhiteMetadata"
          rules={[
            {
              required: true,
              message: 'Bright(White) Logo is required',
            },
          ]}
        >
          <ImageUploader
            className={cn(styles.image_container, styles.image_container_white)}
            content="Upload Bright(White) Logo"
            tooltip="Please upload the insurance provider's logo intended for market-wide use with this insurance partner. Ensure the logo is in SVG format, under 2 MB, and has a resolution of 133.33x60. Using alternative resolutions may result in visual defects."
            valueFormatter={fileUrlFormatter}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            isPartnerUploader
            style={{
              width: 265,
              height: 225,
            }}
          />
        </Form.Item>
        <Form.Item
          name="logoMetadata"
          rules={[
            {
              required: true,
              message: 'Dark Logo is required',
            },
          ]}
        >
          <ImageUploader
            className={cn(styles.image_container, styles.image_container_dark)}
            content="Upload Dark Logo"
            tooltip="Please upload the insurance provider's logo intended for market-wide use with this insurance partner. Ensure the logo is in SVG format, under 2 MB, and has a resolution of 133.33x60. Using alternative resolutions may result in visual defects."
            valueFormatter={fileUrlFormatter}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            isPartnerUploader
            style={{
              width: 265,
              height: 225,
            }}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="seoAltTagWhite"
        rules={[
          { required: true, message: 'seoAltTagWhite is required' },
          { max: 255, message: 'seoAltTagWhite should be at most 255 characters' },
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Bright(White) Logo Alt tag" />
      </Form.Item>
      <Form.Item
        name="seoAltTag"
        rules={[
          { required: true, message: 'seoAltTag is required' },
          { max: 255, message: 'seoAltTag should be at most 255 characters' },
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Dark Logo Alt tag" />
      </Form.Item>
      <Tip>
        Saturn requires two versions of the insurance provider logo, one in bright (white) and the other in dark, to
        ensure accessibility throughout the user journey. The white logo is used in locations with dark backgrounds to
        ensure visibility for all users, including those who may temporarily or permanently have difficulties
        distinguishing colors.
      </Tip>
    </div>
  );
}
