import axios from 'axios';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  AddonsStateRequestDto,
  AddonsStepRequestDto,
  AddonsStepResponseDto,
  AnalyticsRequest,
  ApplicationNumberRequest,
  ApplicationNumberResponse,
  CallbackModalDto,
  CallbackModalResponse,
  CallbackQueryParams,
  CardConfigRequest,
  CardConfigResponse,
  CategoryDto,
  CitiesFromZipResponse,
  ClientDisclosuresRequest,
  ClientFormOption,
  ClientOTPGenerateValidateResponseDto,
  ClientOTPSendRequestDto,
  ClientPaymentConfigRequest,
  ClientProductSalesRequestDto,
  ClientSideProductDetailsDto,
  ClientVerifyOTPRequestDto,
  CommonProductSalesResponseDto,
  DisclosureDto,
  EnrollmentStepDto,
  FinancierNamesOptions,
  FormItem,
  FormItemState,
  FormStateResponseDto,
  IdvResponseDto,
  IndTATACarClientSidePersonalDetailsRequestDto,
  InfoResponse,
  InsuranceCategoryCardDto,
  InsuranceProviderDto,
  IxopayFinalizeConfigDto,
  IxopayFinalizeConfigResponse,
  LandingCategoryDto,
  LocationDetailsDto,
  LocationDto,
  ManufacturerModelsResponse,
  OTPGenerateRequestDto,
  OTPGenerateResponseDto,
  OTPValidateRequestDto,
  PaymentCardTypeDto,
  PaymentConfigRequest,
  PaymentConfigResponse,
  PersonalDetailsClientSideDto,
  PersonalDetailsConfigRequest,
  PersonalDetailsStepDto,
  PlanDetailsDto,
  PlanSelectionStepClientSideRequestDto,
  PlanSelectionStepProductUpsellDto,
  PlanSelectionStepRequestDto,
  PlanSelectionStepResponseDto,
  PlanSelectionV2RequestDto,
  PlanSelectionV2ResponseDto,
  ProductCardDto,
  ProductDetailsClientSideDto,
  ProductDetailsDto,
  ProductDto,
  ProductFormDetailsDto,
  ProductPaymentCardDto,
  ProductSalesPoliciesRequestDto,
  ProductSalesRequestDto,
  ProductSalesResponseDto,
  PromoEventBasicDto,
  PromoEventDetailsDto,
  ProposalPayloadDto,
  QuoteFormInitialDataDto,
  QuoteFormResponseDto,
  QuoteFormStateRequestDto,
  StepModel,
  StepState,
  StepSummaryResponseDto,
  SubformStateRequestDto,
  SubformStateResponseDto,
  SummaryGenericPlanDetailsDto,
  SummaryGenericRequest,
  SummaryResponseDto,
  TataCKYCDto,
  TataCKYCUploadFilePayloadDto,
  TataCKYCVerifyDocumentPayloadDto,
  TataCKYCVerifyOtpPayloadDto,
  Translation,
  UploadDocumentDto,
  UploadDocumentResponseDto,
} from '../models';
import { ProposalDto } from '../models/dto/ProposalDto';
import { getEnvVariable, obfuscateAnalyticsData } from '../utils';

const getData = <T>(promise: Promise<{ data: T }>): Promise<T> => promise.then(res => res?.data);

const addDocumentNameFromHeaders = ({ data, headers }: AxiosResponse<BlobPart>): { data: BlobPart; name: string } => ({
  data,
  name: headers['content-disposition']?.split(';').slice(-1)[0].split('=').slice(-1)[0],
});

const getCookieValue = (name: string) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ?? '';

const maskPII = (url: string) => {
  if (url.includes('payments/config/card-type') || url.includes('personal-details/zip-code')) {
    return url.split('/').toSpliced(-1, 1, '******').join('/');
  }
  return url;
};

export class ApiClient {
  axiosClient: AxiosInstance;

  constructor(params: Partial<AxiosRequestConfig> = {}) {
    this.axiosClient = axios.create({
      baseURL: getEnvVariable('API_ENDPOINT'),
      headers: { 'Content-Type': 'application/json' },
      ...params,
    });
    this.axiosClient.interceptors.request.use(
      config => config,
      (error: AxiosError) => {
        const customError = { ...error, message: `${error.message} for ${maskPII(error.config?.url ?? '')}` };
        return Promise.reject(customError);
      },
    );
    this.axiosClient.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        if (typeof window === 'undefined' && process.env.NODE_ENV !== 'test') {
          console.error(
            `Error while ${error.config?.method?.toUpperCase()} ${error.config?.url} ${error.response?.status} - ${
              error.message
            }`,
          );
          if (error.config?.data) {
            console.error('Request body', JSON.parse(error.config?.data));
          }
          if (error.response?.data) {
            console.error('Response body', error.response?.data);
          }
        }
        const customError = { ...error, message: `${error.message} for ${maskPII(error.config?.url ?? '')}` };
        return Promise.reject(customError);
      },
    );
  }
  getAllowedSlugs = async () => {
    return await getData<string[]>(this.axiosClient.get('/slugs')).then(slugs => Object.values(slugs).flat());
  };

  analyticsRequest = (data: Omit<AnalyticsRequest, 'frontendMode' | 'backendMode'>): void => {
    if (typeof window !== 'undefined' && getEnvVariable('ANALYTICS_ENABLED') !== 'false') {
      void this.axiosClient
        .post(
          '/analytics/events',
          JSON.stringify(
            obfuscateAnalyticsData({
              ...data,
              frontendMode:
                getCookieValue('saturnfrontbg') === 'true' || getCookieValue('allbg') === 'true'
                  ? 'INACTIVE'
                  : 'ACTIVE',
              backendMode:
                getCookieValue('saturnbackbg') === 'true' || getCookieValue('allbg') === 'true' ? 'INACTIVE' : 'ACTIVE',
            }),
          ),
        )
        .catch(() => console.error('Analytics Error'));
    }
  };

  runInfoRequest = (): Promise<InfoResponse> => getData(this.axiosClient.get<InfoResponse>('/info'));

  fetchCategories = (categoryIds: string[]): Promise<CategoryDto[]> =>
    Promise.allSettled(categoryIds.map((id: string) => this.axiosClient.get<CategoryDto>(`/categories/${id}`)))
      .then(responses =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responses.filter(res => res.status === 'fulfilled').map(res => (res as any).value.data as CategoryDto),
      )
      .catch(() => []);

  fetchCategoryCards = async (categoryIds: string[], language: string): Promise<InsuranceCategoryCardDto[]> => {
    if (!categoryIds?.length) return [];
    return getData(
      this.axiosClient.get<InsuranceCategoryCardDto[]>(
        `/categories/cards?categoryIds=${categoryIds.join(',')}&language=${language}`,
      ),
    )
      .then(data => data?.filter(({ info }) => info))
      .catch(() => []);
  };

  fetchCategoryLanding = (categoryId: string, language: string): Promise<LandingCategoryDto> =>
    getData(this.axiosClient.get<LandingCategoryDto>(`/categories/${categoryId}/landing/${language}`));

  fetchCategory = async (categorySlug: string): Promise<CategoryDto> => {
    return getData(this.axiosClient.get<CategoryDto>(`/categories?slug=${categorySlug}`));
  };

  loadI18nMessages = (language: string, locale: string): Promise<Translation> =>
    getData(
      this.axiosClient.get('/i18n', {
        headers: {
          'Accept-Language': `${language}-${locale.toLocaleUpperCase()}`,
        },
      }),
    );

  loadFeatures = (): Promise<Record<string, boolean>> => getData(this.axiosClient.get('/features'));

  fetchInsuranceProviders = async (ids?: string[]): Promise<InsuranceProviderDto[]> => {
    if (!ids?.length) return [];
    return getData(this.axiosClient.get<Array<InsuranceProviderDto>>(`/insurance-providers?ids=${ids}`)).catch(
      () => [],
    );
  };

  getPaymentConfig = (providerId: string, config: PaymentConfigRequest): Promise<PaymentConfigResponse> =>
    getData(this.axiosClient.post<PaymentConfigResponse>(`/insurance-providers/${providerId}/payments/config`, config));

  getClientPaymentConfig = (providerId: string, config: ClientPaymentConfigRequest): Promise<PaymentConfigResponse> =>
    getData(this.axiosClient.post<PaymentConfigResponse>(`/insurance-providers/${providerId}/payments/config`, config));

  getCardConfig = (
    providerId: string,
    cardType: PaymentCardTypeDto,
    config: CardConfigRequest,
  ): Promise<CardConfigResponse> =>
    getData(
      this.axiosClient.post<CardConfigResponse>(
        `/insurance-providers/${providerId}/payments/config/card-type/${cardType}`,
        config,
      ),
    );

  fetchLocationLanding = async <T extends keyof LocationDetailsDto>(
    locationId: string,
    language: string,
    sections: T[] = [],
  ): Promise<Pick<LocationDetailsDto, T>> => {
    return getData(
      this.axiosClient.get<Pick<LocationDetailsDto, T>>(
        `/locations/${locationId}/landing/${language}?sections=${sections.join(',')}`,
      ),
    );
  };

  getLocation = async (locationId: string): Promise<LocationDto> => {
    return getData(this.axiosClient.get<LocationDto>(`/locations?slug=${locationId}`));
  };

  getDisclosures = (
    productId: string,
    language: string,
    stepStates: StepState[],
    enrollmentStep: EnrollmentStepDto,
    planDetails?: PlanDetailsDto,
  ): Promise<DisclosureDto[]> =>
    getData(
      this.axiosClient.post<DisclosureDto[]>(`/products/${productId}/sales/disclosures/${language}`, {
        stepStates,
        enrollmentStep,
        planDetails,
      }),
    );

  getClientDisclosures = (payload: ClientDisclosuresRequest): Promise<DisclosureDto[]> =>
    getData(
      this.axiosClient.post<DisclosureDto[]>(
        `/products/${payload.productId}/sales/disclosures/${payload.language}`,
        payload,
      ),
    );

  tataKYCVerifyDocument = async (data: TataCKYCVerifyDocumentPayloadDto): Promise<TataCKYCDto> => {
    return getData(this.axiosClient.post('/product/in-tata-car-insurance/ckyc/verify-document', data));
  };
  tataKYCUploadFile = async (data: TataCKYCUploadFilePayloadDto): Promise<TataCKYCDto> => {
    return getData(this.axiosClient.post('/product/in-tata-car-insurance/ckyc/file', data));
  };
  tataKYCVerifyOtp = async (data: TataCKYCVerifyOtpPayloadDto): Promise<TataCKYCDto> => {
    return getData(this.axiosClient.post('/product/in-tata-car-insurance/ckyc/verify-otp', data));
  };

  verifyKYC = (data: {
    stepStates: StepState[];
    planDetails: PlanDetailsDto | SummaryGenericPlanDetailsDto;
    kycState: FormItemState[];
  }): Promise<{
    result: 'NOT_FOUND' | 'VERIFIED';
    kycForm: {
      title: string;
      subtitle: string;
      items: FormItem[];
    };
    kycData?: {
      ckycId: string;
      ilkycReferenceNumber: string;
    };
  }> => getData(this.axiosClient.post('/products/in-icici/kyc/verify', data));

  uploadKYCDocument = (data: {
    stepStates: StepState[];
    documents: Array<{
      type: 'AADHAAR' | 'VOTERID' | 'PASSPORT';
      filename: string;
      content: string;
    }>;
  }): Promise<{ result: 'VERIFIED' | 'NOT_FOUND' | 'ERROR'; message?: string }> =>
    getData(this.axiosClient.post('/products/in-icici/kyc/ovd', data));

  generateOTP = (productId: string, detailsData: OTPGenerateRequestDto): Promise<OTPGenerateResponseDto> =>
    getData(this.axiosClient.post<OTPGenerateResponseDto>(`/products/${productId}/otp/generation`, detailsData));

  validateOTP = (productId: string, detailsData: OTPValidateRequestDto): Promise<unknown> =>
    this.axiosClient.post<void>(`/products/${productId}/otp/validation`, detailsData);

  sendOTPClient = (
    productId: string,
    contactInfo: ClientOTPSendRequestDto,
  ): Promise<ClientOTPGenerateValidateResponseDto> =>
    getData(this.axiosClient.post<ClientOTPGenerateValidateResponseDto>(`/product/${productId}/otp/send`, contactInfo));

  validateOTPClient = (
    productId: string,
    detailsData: ClientVerifyOTPRequestDto,
  ): Promise<ClientOTPGenerateValidateResponseDto> =>
    getData(
      this.axiosClient.post<ClientOTPGenerateValidateResponseDto>(`/product/${productId}/otp/verify`, detailsData),
    );

  salesCall = (productId: string, salesData: ProductSalesRequestDto): Promise<CommonProductSalesResponseDto> =>
    getData(this.axiosClient.post<ProductSalesResponseDto>(`/products/${productId}/sales`, salesData));

  clientSalesCall = (productId: string, salesData: ClientProductSalesRequestDto): Promise<ProductSalesResponseDto> =>
    getData(this.axiosClient.post<ProductSalesResponseDto>(`/products/${productId}/sales`, salesData));

  policiesCall = (
    productId: string,
    policyData: ProductSalesPoliciesRequestDto,
  ): Promise<{ data: BlobPart; name: string }> =>
    this.axiosClient
      .post<BlobPart>(`/products/${productId}/sales/policies`, policyData, { responseType: 'arraybuffer' })
      .then(addDocumentNameFromHeaders);

  policiesCallIndiaTata = (policyId: string, policyNumber: string): Promise<{ file: string; fileName: string }> =>
    getData(
      this.axiosClient.get(
        `/product/in-tata-car-insurance/download-policy?policyId=${encodeURIComponent(
          policyId,
        )}&policyNumber=${policyNumber}`,
      ),
    );

  getApplicationNumber = (config: ApplicationNumberRequest): Promise<ApplicationNumberResponse> =>
    getData(this.axiosClient.post<ApplicationNumberResponse>('/products/pnb-metlife/applications', config));

  finalizeSalesCall = (providerId: string, config: IxopayFinalizeConfigDto): Promise<IxopayFinalizeConfigResponse> =>
    getData(
      this.axiosClient.post<IxopayFinalizeConfigResponse>(
        `/insurance-providers/${providerId}/payments/finalization`,
        config,
      ),
    );

  getBenefitIllustrations = (
    benefitIllustrationConfig: ApplicationNumberResponse,
  ): Promise<{ data: BlobPart; name: string }> =>
    this.axiosClient
      .post<BlobPart>('/products/pnb-metlife/benefit-illustrations', benefitIllustrationConfig, {
        responseType: 'arraybuffer',
      })
      .then(addDocumentNameFromHeaders);

  getUploadDocument = (config: UploadDocumentDto): Promise<UploadDocumentResponseDto<UploadDocumentDto>> =>
    getData(
      this.axiosClient.post<UploadDocumentResponseDto<UploadDocumentDto>>(
        '/products/pnb-metlife/document-submit',
        config,
      ),
    );

  getCallbackForm = async ({
    productId,
    categoryId,
    locationId,
  }: CallbackQueryParams): Promise<CallbackModalResponse> => {
    const params = Object.entries({ productId, categoryId, locationId })
      .filter(([, value]) => value != null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return this.axiosClient.get<CallbackModalDto>(`/products/forms/callback?${params}`);
  };

  postProductCallback = (productId: string, state: FormItemState[]): Promise<unknown> =>
    this.axiosClient.post(`/products/${productId}/callback`, { state, pageInfo: { productId } });

  postInspectionCallback = (productId: string, state: FormItemState[]): Promise<unknown> => {
    return this.axiosClient.post(`/products/${productId}/callback`, state);
  };

  fetchProductCards = async (productIds: string[], language: string): Promise<ProductCardDto[]> => {
    if (!productIds?.length) return [];
    return getData(
      this.axiosClient.get<ProductCardDto[]>(`/products/cards?productIds=${productIds.join(',')}&language=${language}`),
    ).catch(() => []);
  };

  fetchProductLanding = (productId: string, language: string): Promise<ProductDetailsDto> =>
    getData(this.axiosClient.get<ProductDetailsDto>(`/products/${productId}/landing/${language}`));

  fetchProductBySlug = async (productSlug: string): Promise<ProductDto> => {
    return getData(this.axiosClient.get<ProductDto>(`/products?slug=${productSlug}`));
  };

  fetchEventBySlug = (eventSlug: string, locationId: string): Promise<PromoEventDetailsDto> =>
    getData(this.axiosClient.get<PromoEventDetailsDto>(`/location-event/landing/${locationId}/${eventSlug}`));

  fetchEventInfoById = (locationEventId: string): Promise<PromoEventBasicDto> =>
    getData(this.axiosClient.get<PromoEventBasicDto>(`/location-event/${locationEventId}`));

  fetchPaymentCards = (productId: string): Promise<Array<ProductPaymentCardDto>> =>
    getData(this.axiosClient.get<Array<ProductPaymentCardDto>>(`/products/${productId}/payment-cards`));

  fetchSteps = (productId: string): Promise<Array<StepModel>> =>
    getData(this.axiosClient.get<Array<StepModel>>(`/products/${productId}/enrollment-steps`));

  getSummaryData = (
    productId: string,
    formType: 'general-information' | 'product-details' | 'personal-details',
    language: string,
    state: FormItemState[],
    items: FormItem[],
  ): Promise<StepSummaryResponseDto | Error> =>
    getData(
      this.axiosClient.post<SummaryResponseDto>(
        `/products/${productId}/forms/${formType}/${language}/summary`,
        JSON.stringify({ form: { items }, state }),
        { headers: { 'Content-Type': 'application/json' } },
      ),
    )
      .then(result => result?.summary ?? null)
      .catch(err => err);

  getPlans = (productId: string, stepsData: PlanSelectionStepRequestDto): Promise<PlanSelectionStepResponseDto> =>
    getData(this.axiosClient.post<PlanSelectionStepResponseDto>(`/products/${productId}/offers`, stepsData));

  getPlansClientSideForm = (
    productId: string,
    stepsData: PlanSelectionStepClientSideRequestDto,
  ): Promise<PlanSelectionStepResponseDto> =>
    getData(this.axiosClient.post<PlanSelectionStepResponseDto>(`/product/${productId}/offers`, stepsData));

  getPlansV2 = (productId: string, stepsData: PlanSelectionV2RequestDto): Promise<PlanSelectionV2ResponseDto> =>
    getData(this.axiosClient.post<PlanSelectionV2ResponseDto>(`/product/${productId}/v2/offers`, stepsData));

  getProductUpsell = (
    targetProductId: string,
    formState: FormItemState[],
  ): Promise<PlanSelectionStepProductUpsellDto | null> =>
    getData(
      this.axiosClient.post<PlanSelectionStepProductUpsellDto | null>(`/products/${targetProductId}/upsell`, {
        state: formState,
      }),
    );

  getIdv = (
    productId: string,
    stepsData: Pick<PlanSelectionStepRequestDto, 'enrollmentDetails'>,
  ): Promise<IdvResponseDto> => getData(this.axiosClient.post<IdvResponseDto>(`/products/${productId}/idv`, stepsData));

  getIdvClientSideForm = (productId: string, stepsData: ClientSideProductDetailsDto): Promise<IdvResponseDto> =>
    getData(this.axiosClient.post<IdvResponseDto>(`/product/${productId}/idv`, stepsData));

  prepareSwitchOver = (
    sourceId: string,
    targetId: string,
    language: string,
    state: FormItemState[],
    planId?: string,
  ): Promise<FormStateResponseDto> =>
    getData(
      this.axiosClient.post<FormStateResponseDto>(
        `/products/${sourceId}/switch-over/${targetId}?language=${language}`,
        {
          state: state ?? [],
          planId,
        },
      ),
    );

  getAddonsForm = (productId: string, stepsData: AddonsStepRequestDto): Promise<AddonsStepResponseDto> =>
    getData(this.axiosClient.post<AddonsStepResponseDto>(`/products/${productId}/forms/addons`, stepsData));

  getAddonsFormState = (productId: string, stepsData: AddonsStateRequestDto): Promise<AddonsStepResponseDto> =>
    getData(this.axiosClient.post<AddonsStepResponseDto>(`/products/${productId}/forms/addons/state`, stepsData));

  getSummaryGenericData = (
    productId: string,
    stepsData: SummaryGenericRequest,
  ): Promise<StepSummaryResponseDto | Error> =>
    getData(
      this.axiosClient.post<SummaryResponseDto>(
        `/products/${productId}/enrollment/summary`,
        JSON.stringify(stepsData),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      ),
    ).then(result => result?.summary ?? null);

  fetchQuoteForms = async (productIds: string[], language: string): Promise<QuoteFormResponseDto[]> => {
    if (!productIds?.length) return [];
    return getData(
      this.axiosClient.get<QuoteFormResponseDto[]>(
        `/products/quote-forms?productIds=${productIds.join(',')}&language=${language}`,
      ),
    ).catch(err => {
      console.error(err);
      return [];
    });
  };

  fetchQuoteFormInitialData = async (productId: string): Promise<QuoteFormInitialDataDto> => {
    return getData(this.axiosClient.get<QuoteFormInitialDataDto>(`/product/${productId}/quote-form`));
  };

  fetchCitiesFromZipCode = async (zipCode: string): Promise<CitiesFromZipResponse> => {
    return getData(
      this.axiosClient.get<CitiesFromZipResponse>(`/product/de-assurant-mobile/personal-details/zip-code/${zipCode}`),
    );
  };

  fetchManufacturerModels = async (productId: string, manufacturerId: string): Promise<ManufacturerModelsResponse> => {
    return getData(
      this.axiosClient.get<ManufacturerModelsResponse>(
        `/product/${productId}/quote-form/manufacturers/${manufacturerId}/models`,
      ),
    );
  };

  fetchProductDetailsClientSide = async (
    productId: string,
    registrationNumber: string | null,
    stepId: string,
  ): Promise<ProductDetailsClientSideDto> => {
    return getData(
      this.axiosClient.post<ProductDetailsClientSideDto>(`/product/${productId}/forms/product-details`, {
        registrationNumber,
        stepId,
      }),
    );
  };
  fetchMakes = async (productId: string): Promise<ClientFormOption[]> => {
    return getData(this.axiosClient.get<ClientFormOption[]>(`/product/${productId}/forms/product-details/make`));
  };
  fetchModels = async (productId: string, make: ClientFormOption): Promise<ClientFormOption[]> => {
    return getData(
      this.axiosClient.get<ClientFormOption[]>(
        `/product/${productId}/forms/product-details/model?makeCode=${make.value}&makeValue=${encodeURIComponent(
          make.label,
        )}`,
      ),
    );
  };
  fetchVariants = async (productId: string, model: ClientFormOption): Promise<ClientFormOption[]> => {
    return getData(
      this.axiosClient.get<ClientFormOption[]>(
        `/product/${productId}/forms/product-details/variant?modelCode=${model.value}&modelValue=${encodeURIComponent(
          model.label,
        )}`,
      ),
    );
  };

  fetchPersonalDetailsClientSide = async (
    productId: string,
    data: IndTATACarClientSidePersonalDetailsRequestDto,
  ): Promise<PersonalDetailsClientSideDto> => {
    return getData(
      this.axiosClient.post<PersonalDetailsClientSideDto>(`/product/${productId}/forms/personal-details`, data),
    );
  };

  searchFinancierNames = async (productId: string, search: string): Promise<FinancierNamesOptions> => {
    if (search.length === 0) {
      return [];
    }
    return getData(
      this.axiosClient.get<FinancierNamesOptions>(
        `/product/${productId}/forms/personal-details/financier-name?name=${search}`,
      ),
    );
  };

  getProposal = async (productId: string, data: ProposalPayloadDto): Promise<ProposalDto> => {
    return getData(this.axiosClient.post<ProposalDto>(`/product/${productId}/proposal`, data));
  };

  makeClientSideSwitchOver = (
    productId: string,
    data: { destinationCountryCodes: string[] },
  ): Promise<{ regionCode: string; travelRegions: { label: string; value: string }[] }> =>
    getData(
      this.axiosClient.post<{ regionCode: string; travelRegions: { label: string; value: string }[] }>(
        `/product/${productId}/switch-over`,
        data,
      ),
    );

  searchLocations = async (
    productId: string,
    searchObject: Record<string, string>,
  ): Promise<{ code: string; name: string }[]> => {
    if (searchObject.name?.length < 3) {
      return [];
    }
    const queryString = new URLSearchParams(searchObject).toString();
    return getData(
      this.axiosClient.get<{ code: string; name: string }[]>(
        `/product/${productId}/personal-details/locations?${queryString}`,
      ),
    );
  };

  searchZipCode = async (productId: string, postalCode: string): Promise<CitiesFromZipResponse> => {
    return getData(
      this.axiosClient.get<CitiesFromZipResponse>(`/product/${productId}/personal-details/zip-code/${postalCode}`),
    );
  };

  getQuoteFormState = (formId: string, data: QuoteFormStateRequestDto): Promise<FormStateResponseDto> =>
    getData(this.axiosClient.post<FormStateResponseDto>(`/products/quote-forms/${formId}/state`, data));

  getProductForm = (
    productId: string,
    language: string,
    planId: string,
    customerAnswers: FormItemState[],
  ): Promise<ProductFormDetailsDto> =>
    getData(
      this.axiosClient.post<ProductFormDetailsDto>(`/products/${productId}/forms/product-details/${language}`, {
        planId,
        customerAnswers,
      }),
    );

  getPersonalDetailsForm = (
    productId: string,
    language: string,
    config: PersonalDetailsConfigRequest,
  ): Promise<PersonalDetailsStepDto> =>
    getData(
      this.axiosClient.post<PersonalDetailsStepDto>(
        `/products/${productId}/forms/personal-details/${language}`,
        config,
      ),
    );

  getSubFormState = (
    formId: string,
    subformId: string,
    state: SubformStateRequestDto,
  ): Promise<SubformStateResponseDto> =>
    getData(
      this.axiosClient.post<SubformStateResponseDto>(`/products/forms/${formId}/subforms/${subformId}/state`, state),
    );

  getCacheTimeStamp = async (locationId: string): Promise<Record<string, number> | null> => {
    return getData(this.axiosClient.get<Record<string, number>>(`/locations/cache-key/${locationId}/timestamps`)).catch(
      () => null,
    );
  };
}

const browserApi = new ApiClient();

const loggingDecorator = (target: ApiClient) => {
  const descriptors = Object.getOwnPropertyDescriptors(target);
  for (const [propName, descriptor] of Object.entries(descriptors)) {
    const isMethod = typeof descriptor.value == 'function' && propName !== 'constructor' && propName !== 'axiosClient';
    if (!isMethod) continue;
    const originalMethod = descriptor.value;
    /* eslint-disable-next-line */
    descriptor.value = async function (...args: any[]) {
      const result = await originalMethod(...args);
      try {
        const finalResult = propName === 'getCallbackForm' ? result.data : result;
        axios.post('http://localhost:3012/log', { functionName: propName, args, response: finalResult });
      } catch (e) {
        console.log(`=================== Could not log request for ${propName} ===================`);
      }
      return result;
    };
    Object.defineProperty(target, propName, descriptor);
  }
};

export function createServerApi({
  language,
  locale,
  backbgCookieValue,
  referer,
}: {
  language: string;
  locale: string;
  backbgCookieValue?: string;
  referer?: string;
}): ApiClient {
  const apiClient = new ApiClient();
  if (process.env.NEXT_PUBLIC_API_LOGGING_ENABLED) {
    loggingDecorator(apiClient);
  }
  apiClient.axiosClient.interceptors.request.use(config => {
    const cookieString = `saturnbackbg=${String(backbgCookieValue === 'true')}`;
    if (!String(config.headers?.Cookie)?.includes(cookieString)) {
      config.headers = config.headers ?? {};
      config.headers.Cookie = [config.headers.Cookie, cookieString].filter(Boolean).join(';');
    }
    return config;
  }, Promise.reject);

  if (referer) {
    apiClient.axiosClient.defaults.headers.common['Referer'] = referer;
  }

  if (language && locale) {
    apiClient.axiosClient.defaults.headers.common['Accept-Language'] = `${language}-${locale.toLocaleUpperCase()}`;
  }
  return apiClient;
}

export function setBrowserClientHeaders(
  target: ApiClient,
  params: {
    language: string;
    locale: string;
  },
): void {
  const { language, locale } = params;
  if (language && locale) {
    target.axiosClient.defaults.headers.common['Accept-Language'] = `${language}-${locale.toLocaleUpperCase()}`;
  }
}

if (process.env.NEXT_PUBLIC_API_LOGGING_ENABLED) {
  loggingDecorator(browserApi);
}
export default browserApi;
