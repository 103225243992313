import { Form, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip } from 'shared/components';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const DisclaimerSection = ({ header }: { header: string }) => {
  return (
    <CollapsiblePanel header={header || 'Disclaimer'} name={['disclaimerSection']} withoutSwitch>
      <MarkdownTip>
        <Form.Item
          name={['disclaimerSection', 'disclaimerText']}
          rules={[
            {
              max: 250,
              message: 'Disclaimer Text should be at most 250 characters',
            },
          ]}
        >
          <DebouncedInputWrapper element={Textarea} label="Disclaimer Text" />
        </Form.Item>
      </MarkdownTip>
    </CollapsiblePanel>
  );
};
