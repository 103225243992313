import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import styles from './QuoteNow.module.scss';
import QuoteNowProductBlock from './QuoteNowProductBlock';

type QuoteNowProps = {
  header?: string;
};
export default function QuoteNow({ header }: QuoteNowProps) {
  return (
    <CollapsiblePanel header={header || 'Quote Now'} name={['quoteNow']}>
      <ContentBlockContainer
        name={['quoteNow', 'products']}
        className={styles.content}
        title="Product"
        addButtonText="Add Product"
        showChangePositionButton={true}
        maxItems={10}
        isCollapsible
      >
        {props => {
          return (
            <div className={styles.blocksContentWrapper}>
              <QuoteNowProductBlock {...props} />
            </div>
          );
        }}
      </ContentBlockContainer>
    </CollapsiblePanel>
  );
}
