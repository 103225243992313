import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { admin } from '@saturn/api';

interface FeaturesProviderProps {
  children?: ReactNode;
}

interface FeaturesContextProps {
  features: Record<string, boolean>;
  isFeatureEnabled: (featureName: string) => boolean;
  featuresLoading: boolean;
}

export const FeaturesContext = createContext<FeaturesContextProps>({
  features: {},
  isFeatureEnabled: () => false,
  featuresLoading: false,
});

export const useFeaturesContext = (): FeaturesContextProps => useContext(FeaturesContext);

const featuresLocal: Record<string, boolean> = {
  // TODO: remove in the scope of AADTT-21303
  'gb-chubb-plan-selection-new-design': true,
};

export const FeaturesProvider: FC<FeaturesProviderProps> = ({ children }) => {
  const location = useLocation();
  const { data: featuresBE = {}, loading } = useRequest(() => admin.loadFeatures(), {
    refreshDeps: [location],
  });
  const features = process.env.NODE_ENV === 'development' ? { ...featuresBE, ...featuresLocal } : featuresBE;
  const isFeatureEnabled = (name: string) => {
    return features ? Boolean(name in features && features[name]) : false;
  };

  return (
    <FeaturesContext.Provider
      value={{
        features,
        isFeatureEnabled,
        featuresLoading: loading,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};
