import { Form, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, IconsGallery, LanguageTabs, MarkdownTip } from 'shared/components';
import type { ContentBlockProps } from 'shared/components';

import styles from './PromotionBenefits.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';
import { normalizeEmptyStringToNull } from '../../../shared/utils';

type PromotionBenefitsV2Props = {
  header?: string;
};

export function PromotionBenefitsV3({ header }: PromotionBenefitsV2Props) {
  return (
    <LanguageTabs isShowLeaveModal={false}>
      <CollapsiblePanel header={header || 'Promotion Benefits'} name={['promocodeBenefits']} withoutSwitch>
        <ContentBlockContainer
          className={styles.content}
          maxItems={20}
          name={['promocodeBenefits']}
          addButtonText="Add set of promo benfits per plan"
          title="Promotion Benefits Set"
          isCollapsible
        >
          {props => <PromotionBenefitBlock {...props} />}
        </ContentBlockContainer>
      </CollapsiblePanel>
    </LanguageTabs>
  );
}

function isValidPromoCodeValueEmptiness(data: { promocodeValue?: string | null }[]) {
  let count = 0;

  for (const item of data) {
    if (!item || item?.promocodeValue === undefined || item?.promocodeValue === '' || item?.promocodeValue === null) {
      count++;
    }
  }
  return count <= 1;
}

function PromotionBenefitBlock({ name: blockName, ...fieldProps }: ContentBlockProps) {
  let hasError = false;
  return (
    <>
      <MarkdownTip content="Empty value for promocode - results in application of the configuration to all promocodes for the product (including plan benefit titles and plan benefit descriptions). Exception is done only for those promocode values that are configured explicitly in other blocks - configured promocodes with stated promocode values, plan benefit titles, and plan benefit descriptions.">
        <Form.Item shouldUpdate>
          <Form.Item
            {...fieldProps}
            normalize={normalizeEmptyStringToNull}
            name={[blockName, 'promocodeValue']}
            rules={[
              ({ getFieldValue, validateFields, setFields }) => ({
                validator(_, value) {
                  const promocodeBenefits = getFieldValue('promocodeBenefits');
                  if (!isValidPromoCodeValueEmptiness(promocodeBenefits) && !value) {
                    hasError = true;
                    return Promise.reject(new Error('Only one promocode value can be empty'));
                  }
                  const hasDuplicates =
                    promocodeBenefits.filter((d: { promocodeValue?: string }) => d?.promocodeValue === value).length >
                    1;
                  if (hasDuplicates) {
                    hasError = true;
                    setFields([{ name: ['promocodeBenefits'], errors: ['uniqu'] }]);
                    return Promise.reject(new Error('Promocode values should be unique'));
                  } else {
                    if (hasError) {
                      validateFields();
                      hasError = false;
                    }
                    return Promise.resolve();
                  }
                },
              }),
              {
                min: 3,
                message: 'Promocode value should be at least 3 characters',
              },
              {
                max: 50,
                message: 'Promocode value be at most 50 characters',
              },
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Promocode value" />
          </Form.Item>
        </Form.Item>
      </MarkdownTip>

      <div className={styles.paddedContent}>
        <ContentBlockContainer
          name={[blockName, 'benefitPlans']}
          maxItems={5}
          className={styles.content}
          title="Promo benefit per plan"
          addButtonText="Add Plan"
          showChangePositionButton={true}
        >
          {props => <BenefitBlock {...props} />}
        </ContentBlockContainer>
      </div>
    </>
  );
}

function BenefitBlock({ name: blockName, parentName, ...fieldProps }: ContentBlockProps) {
  let hasError = false;
  return (
    <div>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const promocodeBenefits = getFieldValue('promocodeBenefits');
          return (
            <>
              <Form.Item
                {...fieldProps}
                normalize={normalizeEmptyStringToNull}
                rules={[
                  {
                    required: promocodeBenefits.length > 1,
                    message: 'Plan Name is required',
                    whitespace: true,
                  },
                  ({ validateFields }) => ({
                    validator(_, value) {
                      const benefitPlans = getFieldValue(['promocodeBenefits', ...parentName]) ?? [];
                      const hasDuplicates =
                        benefitPlans.filter((d: { planName?: string }) => d?.planName === value).length > 1;
                      if (hasDuplicates) {
                        hasError = true;
                        return Promise.reject(new Error('Plan name values should be unique'));
                      } else {
                        if (hasError) {
                          validateFields();
                          hasError = false;
                        }
                        return Promise.resolve();
                      }
                    },
                  }),
                  {
                    min: 3,
                    message: 'Plan Name should be at least 3 characters',
                  },
                  {
                    max: 50,
                    message: 'Plan Name be at most 50 characters',
                  },
                ]}
                name={[blockName, 'planName']}
              >
                <DebouncedInputWrapper element={Input} label="Plan Name" />
              </Form.Item>
              <Form.Item
                {...fieldProps}
                normalize={normalizeEmptyStringToNull}
                rules={[
                  {
                    required: true,
                    message: 'Benefit title is required',
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: 'Benefit title should be at least 3 characters',
                  },
                  {
                    max: 50,
                    message: 'Benefit title be at most 50 characters',
                  },
                ]}
                name={[blockName, 'benefitTitle']}
              >
                <DebouncedInputWrapper element={Input} label="Benefit title" />
              </Form.Item>
              <Form.Item
                {...fieldProps}
                className={styles.iconInput}
                preserve={false}
                valuePropName="icon"
                name={[blockName, 'benefitTitleIcon']}
              >
                <IconsGallery />
              </Form.Item>
              <MarkdownTip>
                <Form.Item
                  {...fieldProps}
                  normalize={normalizeEmptyStringToNull}
                  name={[blockName, 'benefitDescription']}
                  rules={[
                    {
                      max: 150,
                      message: 'Benefit Description be at most 150 characters',
                    },
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="Benefit Description" />
                </Form.Item>
              </MarkdownTip>
            </>
          );
        }}
      </Form.Item>
    </div>
  );
}
