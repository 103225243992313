import type { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';

import { Empty } from '@saturn/uikit';

import AnalyticsPage from 'pages/analytics';
import CategoriesPage from 'pages/categories';
import CategoriesCreatePage from 'pages/categories-create';
import CategoryLandingPage from 'pages/category-landing';
import ErrorReportPage from 'pages/errors';
import EventCreatePage from 'pages/event-create';
import EventLandingPage from 'pages/event-landing';
import EventsPage from 'pages/events';
import HealthCheckPage from 'pages/health';
import ListOfPoliciesPage from 'pages/list-of-policies';
import LocationLandingPage from 'pages/location-landing';
import LocationSettingsPage from 'pages/location-settings';
import { LoginPage } from 'pages/login';
import LogsPage from 'pages/logs';
import PolicyDetailsPage from 'pages/policy-details';
import ProductLandingPage from 'pages/product-landing';
import TranslationsPage from 'pages/product-translate';
import ProductsPage from 'pages/products';
import ProductsCreatePage from 'pages/products-create';
import UsersPage from 'pages/users';
import UsersCreatePage from 'pages/users-create';

import { MainLayout } from 'shared/components';
import { ProtectedRoute } from 'shared/components/ProtectedRoute';
import { AdminLocationContextProvider, UserContextProvider, FeaturesProvider } from 'shared/contexts';
import { configureApi } from 'shared/services/api';

import InsurancePartnerLandingPage from './pages/insurance-partner-landing';
import InsurancePartners from './pages/insurance-partners';
import PromocodeCreatePage from './pages/promocode-create';
import PromocodeLanding from './pages/promocode-landing';
import PromocodesPage from './pages/promocodes';

import './styles/_global.scss';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

configureApi();
const LOGIN_URL = '/login';
const App: FC = () => {
  return (
    <Router>
      <IntlProvider
        locale="en"
        onError={() => {
          // Omit translation erros logs for the application perfomance
          return;
        }}
      >
        <FeaturesProvider>
          <UserContextProvider>
            <AdminLocationContextProvider>
              <Routes>
                <Route index element={<Navigate to={LOGIN_URL} replace />} />
                <Route path={LOGIN_URL} element={<LoginPage />} />
                <Route
                  path="/:locationId"
                  element={
                    <ProtectedRoute redirectTo={LOGIN_URL}>
                      <MainLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<Navigate to="locations" replace />} />
                  <Route path="locations" element={<LocationLandingPage />} />
                  <Route path="location/settings" element={<LocationSettingsPage />} />
                  <Route path="categories" element={<CategoriesPage />} />
                  <Route path="categories/create" element={<CategoriesCreatePage />} />
                  <Route path="categories/:categoryId" element={<CategoryLandingPage />} />
                  <Route path="products" element={<ProductsPage />} />
                  <Route path="products/create" element={<ProductsCreatePage />} />
                  <Route path="products/:productId" element={<ProductLandingPage />} />
                  <Route path="products/:productId/translate" element={<TranslationsPage />} />
                  <Route path="insurance-partners" element={<InsurancePartners />} />
                  <Route path="insurance-partners/:partnerId" element={<InsurancePartnerLandingPage />} />
                  <Route path="promocodes" element={<PromocodesPage />} />
                  <Route path="promocodes/:promocodeId" element={<PromocodeLanding />} />
                  <Route path="promocodes/create" element={<PromocodeCreatePage />} />
                  <Route path="events" element={<EventsPage />} />
                  <Route path="events/create" element={<EventCreatePage />} />
                  <Route path="events/:eventId" element={<EventLandingPage />} />
                  <Route path="users" element={<UsersPage />} />
                  <Route path="users/create" element={<UsersCreatePage />} />
                  <Route path="list-of-policies" element={<ListOfPoliciesPage />} />
                  <Route path="policy-details" element={<PolicyDetailsPage />} />
                  <Route path="analytics" element={<AnalyticsPage />} />
                  <Route path="error-report" element={<ErrorReportPage />} />
                  <Route path="logs" element={<LogsPage />} />
                  <Route path="health" element={<HealthCheckPage />} />
                  <Route path="*" element={<Empty description="Not Found" />} />
                </Route>
              </Routes>
            </AdminLocationContextProvider>
          </UserContextProvider>
        </FeaturesProvider>
      </IntlProvider>
    </Router>
  );
};

export default App;
