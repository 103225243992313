import { Form, Input } from '@saturn/uikit';

import { ContentBlockProps, IconsGallery } from 'shared/components';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export default function PlanSelectionBlock({ required, ...fieldProps }: ContentBlockProps) {
  const blockName = fieldProps.name;
  return (
    <div>
      <Form.Item
        name={[blockName, 'title']}
        rules={
          required
            ? [
                {
                  min: 1,
                  message: 'Title should be at least 1 character',
                },
                {
                  max: 255,
                  message: 'Title should be at most 255 characters',
                },
              ]
            : []
        }
      >
        <DebouncedInputWrapper element={Input} label="Benefit Title" />
      </Form.Item>
      <Form.Item
        name={[blockName, 'tip']}
        rules={
          required
            ? [
                {
                  min: 1,
                  message: 'Tooltip should be at least 1 character',
                },
                {
                  max: 500,
                  message: 'Tooltip should be at most 500 characters',
                },
              ]
            : []
        }
      >
        <DebouncedInputWrapper element={Input} label="Tooltip" />
      </Form.Item>
      <Form.Item preserve={false} valuePropName="icon" name={[blockName, 'thumbnailId']}>
        <IconsGallery />
      </Form.Item>
    </div>
  );
}
