import map from 'lodash/map';
import startCase from 'lodash/startCase';

import {
  PRODUCT_TYPE_API,
  PRODUCT_TYPE_NON_API,
  ROLE_ADMIN,
  ROLE_CM,
  ROLE_SUPER_ADMIN,
  SUPPORTED_ROLE,
} from '@saturn/api';

export const TEXT_BLOCK_SELECT = {
  textOnly: { text: 'Text only', value: 'textOnly' },
  imageLeftTextRight: { text: 'Image Left + Text Right', value: 'imageLeftTextRight' },
  textLeftImageRight: { text: 'Text Left + Image Right', value: 'textLeftImageRight' },
};

export const STANDALONE_DOCUMENTS_ALIGNMENT_SELECT = {
  left: { text: 'Left', value: 'LEFT' },
  right: { text: 'Right', value: 'RIGHT' },
  center: { text: 'Center', value: 'CENTER' },
};

export const DOCUMENT_TYPE = {
  link: 'Link',
  fileUpload: 'File Upload',
};

export const DISABILITY_CONFIRMATION_BUTTON_TIME = 10000;

const prepareRoles = (
  roles: SUPPORTED_ROLE[],
): {
  label: string;
  value: SUPPORTED_ROLE;
}[] => {
  return map(roles, role => ({ label: startCase(role), value: role }));
};
export const ALL_ROLES = prepareRoles([ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CM]);
export const ROLES_ADMIN_CM = prepareRoles([ROLE_ADMIN, ROLE_CM]);
export const PRODUCT_TYPES = [PRODUCT_TYPE_API, PRODUCT_TYPE_NON_API].map(t => ({ label: t, value: t }));

export const PROMPT_MESSAGE = 'Are you sure you want to leave this page? Your data will not be saved.';

const CHAR_LIMIT = 30000;

export const FOOTER_DISCLOSURES_MAX_LENGTH_RULE = {
  max: CHAR_LIMIT,
  message: `Text Block should be at most ${CHAR_LIMIT} characters`,
} as const;
