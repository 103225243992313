import { PromocodeBenefitV3 } from '@saturn/api/src/models/dto/PromocodeDto';

export const hasPromocodeValueChanged = (changedField: PromocodeBenefitV3) => {
  const benefits = changedField.promocodeBenefits;
  const promocodeValueChanged = benefits.some(benefit => benefit && 'promocodeValue' in benefit);

  if (promocodeValueChanged) {
    return true;
  }
};
