import type { FC } from 'react';

import cn from 'classnames';

import { ProductDto } from '@saturn/api';
import { Form, Icon, Option, Select, Switch, Textarea, Tooltip } from '@saturn/uikit';

import { CollapsiblePanel, InsuranceProductsBlock } from 'shared/components';

import styles from './InsuranceProducts.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

interface InsuranceProductsProps {
  products: ProductDto[];
  selectedInsuranceProducts?: string[];
  header?: string;
}

const CARDS_ALIGNMENT = {
  left: { text: 'Left', value: 'left' },
  center: { text: 'Center', value: 'center' },
  right: { text: 'Right', value: 'right' },
};

export const InsuranceProducts: FC<InsuranceProductsProps> = ({
  products = [],
  selectedInsuranceProducts = [],
  header,
}) => {
  return (
    <CollapsiblePanel
      header={header || 'Products'}
      name={['productsSection']}
      showSectionTitle
      validationRules={[{ min: 3, message: 'Section Title should be at least 3 characters' }]}
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['productsSection', 'visible']);
          return (
            <Form.Item
              name={['productsSection', 'sectionDescription']}
              rules={[
                ...(isFieldRequired
                  ? [
                      {
                        max: 255,
                        message: 'Section Description should be at most 255 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <DebouncedInputWrapper element={Textarea} label="Section Description" />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div className={styles.flexWrapper}>
        <div className={cn(styles.flexItem, styles.flexWrapperInner)}>
          <p className={styles.label}>Display Carousel?</p>
          <Form.Item name={['productsSection', 'isCarousel']} valuePropName="checked">
            <Switch checkedChildren="on" unCheckedChildren="off" />
          </Form.Item>
          <Tooltip title="If activated, product cards would reside within the carousel instead of being immediately displayed on the page.">
            <Icon size={25} name="question-mark-circle-outline" />
          </Tooltip>
        </div>
        <div className={styles.flexItem}>
          <Form.Item
            shouldUpdate={(prevValue, newValue) =>
              prevValue.productsSection?.isCarousel !== newValue.productsSection?.isCarousel
            }
          >
            {({ getFieldValue }) => {
              const isCarousedMode = getFieldValue(['productsSection', 'isCarousel']);

              return isCarousedMode ? null : (
                <div className={styles.flexWrapperInner}>
                  <Form.Item name={['productsSection', 'cardsAlignment']} className={styles.fullWidth}>
                    <Select placeholder="Cards alignment" defaultValue={CARDS_ALIGNMENT.left.value}>
                      {Object.values(CARDS_ALIGNMENT).map(({ text, value }) => (
                        <Option key={value} data-key={value} value={value}>
                          {text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Tooltip title="Adjusts the alignments of cards within the section.">
                    <Icon size={25} name="question-mark-circle-outline" />
                  </Tooltip>
                </div>
              );
            }}
          </Form.Item>
        </div>
      </div>

      <InsuranceProductsBlock
        title="Products"
        name={['productsSection', 'products']}
        maxItems={12}
        products={products}
        selectedInsuranceProducts={selectedInsuranceProducts}
      />
    </CollapsiblePanel>
  );
};
