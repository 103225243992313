import type { FC } from 'react';

import { Form, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, MarkdownTip } from 'shared/components';

import { FOOTER_DISCLOSURES_MAX_LENGTH_RULE } from '../../constants';
import { FooterDisclosuresProps } from './model';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

export const FooterDisclosures: FC<FooterDisclosuresProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Footer Disclosures'} name={['disclosuresFooter']} showSectionTitle>
      <MarkdownTip>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue(['disclosuresFooter', 'visible']);
            return (
              <Form.Item
                name={['disclosuresFooter', 'disclosureContent']}
                rules={[...(isFieldRequired ? [FOOTER_DISCLOSURES_MAX_LENGTH_RULE] : [])]}
              >
                <DebouncedInputWrapper element={Textarea} label="Disclosures" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </MarkdownTip>
    </CollapsiblePanel>
  );
};
