import { AEROLAB_ICONS as aerolabIcons } from './aerolab';
import { FLAG_ICONS as flagIcons } from './flags';
import { LIBRARY_ICONS as libIcons } from './lib';

export const systemIcons = {
  'burger-menu': 'eva/menu.svg',
  'grid-outline': 'eva/grid-outline.svg',
  'phone-call-outline': 'eva/phone-call-outline.svg',
  'arrow-upward-outline': 'eva/arrow-upward-outline.svg',
  'arrow-downward-outline': 'eva/arrow-downward-outline.svg',
  'minus-outline': 'eva/minus-outline.svg',
  'plus-outline': 'eva/plus-outline.svg',
  'close-outline': 'eva/close-outline.svg',
  'download-outline': 'eva/download-outline.svg',
  'email-outline': 'eva/email-outline.svg',
  'business-card-alt': 'eva/business-card.svg',
  compass: 'eva/compass.svg',
  'calendar-outline': 'eva/calendar-outline.svg',
  'arrow-down': 'eva/arrow-down.svg',
  'arrow-up': 'eva/arrow-up.svg',
  'arrow-back': 'eva/arrow-back.svg',
  'arrow-right-alt': 'eva/arrow-right-alt.svg',
  'arrow-ios-downward-alt': 'eva/arrow-ios-downward-alt.svg',
  'arrow-ios-upward-outline': 'eva/arrow-ios-upward-outline.svg',
  'arrow-ios-downward-outline': 'eva/arrow-ios-downward-outline.svg',
  'arrow-forward-outline': 'eva/arrow-forward-outline.svg',
  'question-mark-circle-outline': 'eva/question-mark-circle-outline.svg',
  'trash-2-outline': 'eva/trash-2-outline.svg',
  'sync-outline': 'eva/sync-outline.svg',
  'file-text-outline': 'eva/file-text-outline.svg',
  'alert-triangle-outline': 'eva/alert-triangle-outline.svg',
  'alert-triangle': 'eva/alert-triangle.svg',
  'eye-off-outline': 'eva/eye-off-outline.svg',
  'eye-outline': 'eva/eye-outline.svg',
  'bulb-outline': 'eva/bulb-outline.svg',
  'flip-2-outline': 'eva/flip-2-outline.svg',
  'lock-icon': 'eva/lock.svg',
  'checkmark-circle-outline': 'eva/checkmark-circle-outline.svg',
  'edit-outline': 'eva/edit-outline.svg',
  checkmark: 'eva/checkmark.svg',
  'shield-lock': 'eva/shield-lock.svg',
  'arrow-ios-back': 'eva/arrow-ios-back.svg',
  'arrow-ios-forward': 'eva/arrow-ios-forward.svg',
  'arrow-back-outline': 'eva/arrow-back-outline.svg',
  'search-outline': 'eva/search-outline.svg',
  'square-outline': 'eva/square-outline.svg',
  'close-circle-outlined': 'eva/close-circle-outline.svg',
  'edit-2-outline': 'eva/edit-2-outline.svg',
  'repeat-outline': 'eva/repeat-outline.svg',
  info: 'eva/info.svg',
  'alert-circle-outline': 'eva/alert-circle-outline.svg',
  'chevron-up-outline': 'eva/chevron-up-outline.svg',
  'chevron-right-light': 'eva/chevron-right-light.svg',
  star: 'eva/star.svg',
} as const;
export const LIBRARY_ICONS = libIcons;
export const FLAG_ICONS = flagIcons;
export const AEROLAB_ICONS = aerolabIcons;
export const iconsMap = {
  ...libIcons,
  ...flagIcons,
  ...systemIcons,
  ...aerolabIcons,
};

export type IconName = keyof typeof iconsMap | (string & {});
