import { useParams } from 'react-router-dom';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import cn from 'classnames';

import { PRODUCT_TYPE_API, PRODUCT_TYPE_NON_API, admin, getLocationTimezone } from '@saturn/api';
import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  Option,
  RadioGroup,
  Select,
  Switch,
  Textarea,
  Tooltip,
} from '@saturn/uikit';

import { CollapsiblePanel, SubTitle } from 'shared/components';
import { PRODUCT_TYPES } from 'shared/constants';
import { prepareOptions, useAdminLocation } from 'shared/utils';
import type { ProviderOption } from 'shared/utils';

import styles from './SystemInfo.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';
import { useFeaturesContext } from 'shared/contexts/FeaturesContext';

interface SystemInfoProps {
  name?: string;
  type?: string;
  providerId?: string;
  categoryId?: string | null;
  disabled?: boolean;
  isSlugRequiredForExtendedProduct?: boolean;
  providers: ProviderOption[];
  loadingProviders: boolean;
}

export function SystemInfo({
  type,
  disabled = false,
  isSlugRequiredForExtendedProduct,
  providers,
  loadingProviders,
}: SystemInfoProps): JSX.Element {
  const { adminLocation } = useAdminLocation();
  const locationTimezone = getLocationTimezone(adminLocation.id);
  const { productId = '' } = useParams();
  const { isFeatureEnabled } = useFeaturesContext();
  const arePromocodesV3 = isFeatureEnabled('cms-promocode-v3-enabled');

  const { data: productPromocodes = [] } = useRequest(
    async () => {
      const res = await admin.getPromocodes(adminLocation.id);
      return res.promocodes.filter(p => p.productId === productId);
    },
    {
      refreshDeps: [adminLocation.id],
    },
  );

  const { data: categories = [], loading: loadingCategories } = useRequest(
    async () =>
      (await admin.getCategoriesList(adminLocation?.id))
        .map(({ name, id, hasLandingPage }) => ({ label: name, value: id, hasLandingPage }))
        .filter(data => (type === PRODUCT_TYPE_NON_API ? data.hasLandingPage : true)),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const disabledStartDate = (date: Dayjs) => {
    const minDate = dayjs().utcOffset(locationTimezone).startOf('day');
    const maxDate = dayjs().utcOffset(locationTimezone).add(1, 'month').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  const disabledEndDates = (date: Dayjs | null, startDate?: string) => {
    const customFormat = 'DD-MM-YYYY';
    const formattedStart = startDate && dayjs(startDate)?.format(customFormat);
    const formattedDate = date?.format(customFormat);

    const start = formattedStart
      ? dayjs(formattedStart, customFormat).utcOffset(locationTimezone)
      : dayjs().utcOffset(locationTimezone);

    const minDate = start.clone().add(1, 'day').startOf('day');

    const maxDate = minDate.clone().add(3, 'months').endOf('day');

    const isPastMin = dayjs(formattedDate, customFormat)?.utcOffset(locationTimezone).isBefore(minDate);
    const isPastMax = dayjs(formattedDate, customFormat).utcOffset(locationTimezone).isAfter(maxDate);

    return !date || isPastMin || isPastMax;
  };

  const datePickerNewFooter = (onChange: ((date: Dayjs | null) => void) | undefined) => {
    return (
      <div className={styles.footer}>
        <Button
          onClick={() => {
            const now = dayjs().utcOffset(locationTimezone);
            if (onChange) {
              onChange(now);
            }
          }}
          type="text"
          className={cn(styles.extraPickerBtn, styles.alignLeft)}
        >
          Now
        </Button>
        <Button
          onClick={() => {
            if (onChange) {
              onChange(null);
            }
            const AntClear = document.querySelector('.startDate .ant-picker-clear') as HTMLElement;
            if (AntClear) {
              AntClear?.click();
            }
          }}
          type="text"
          className={styles.extraPickerBtn}
        >
          Clear all
        </Button>
      </div>
    );
  };

  return (
    <CollapsiblePanel header="System Info" name={['systemInfo']} withoutSwitch>
      <div className={styles.content}>
        {disabled && (
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Name is required',
              whitespace: true,
            },
            {
              max: 100,
              message: 'Name should be at most 100 characters',
            },
            {
              pattern: /^[A-Za-z\d-\s]*$/,
              message: 'There are restricted symbols in the value',
            },
          ]}
        >
          <DebouncedInputWrapper element={Input} label="Name" />
        </Form.Item>
        {isSlugRequiredForExtendedProduct || type === PRODUCT_TYPE_API ? (
          <Form.Item
            name="slugPart"
            rules={[
              ...(type === PRODUCT_TYPE_API || (type === PRODUCT_TYPE_NON_API && isSlugRequiredForExtendedProduct)
                ? [{ required: true, message: 'Slug is required' }]
                : []),
              { max: 40, message: 'Slug should be at most 40 characters' },
              { min: 3, message: 'Slug should be at least 3 characters' },
              {
                pattern: /^[A-Za-z\d-]*$/,
                message: 'There are restricted symbols in the value',
              },
            ]}
          >
            <DebouncedInputWrapper
              element={Input}
              label="Slug"
              addonAfter={
                <Tooltip
                  title={
                    'SLUG is the last part of url after the last "/". Please note that the page of a product is not available by old url after change of slug. The value may contain Roman characters, digits, and dashes. Min-max size of the value is 3-40 chars. And it must be unique per product.'
                  }
                >
                  <Icon size={35} name="question-mark-circle-outline" />
                </Tooltip>
              }
            />
          </Form.Item>
        ) : null}
        <Form.Item name="locationId" hidden>
          <Input />
        </Form.Item>
        {disabled && (
          <Form.Item name="status" hidden>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: 'Type is required',
            },
          ]}
        >
          <Select placeholder="Type" disabled>
            {prepareOptions(PRODUCT_TYPES)}
          </Select>
        </Form.Item>
        <Form.Item
          name="providerId"
          rules={[
            {
              required: true,
              message: 'Insurance Provider is required',
            },
          ]}
        >
          <Select placeholder="Insurance Provider" disabled={disabled} loading={loadingProviders}>
            {prepareOptions(providers)}
          </Select>
        </Form.Item>
        <Form.Item
          name="categoryId"
          rules={[
            {
              required: true,
              message: 'Insurance Category is required',
            },
          ]}
        >
          <Select placeholder="Insurance Category" disabled={disabled} loading={loadingCategories}>
            {prepareOptions(categories)}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.togglersContnet}>
        {type === 'API' && (
          <>
            {!arePromocodesV3 && (
              <div>
                <SubTitle>Promocode</SubTitle>
                <div className={styles.toggleWrapper}>
                  <Form.Item name={['marketingDetails', 'hasPromocode']} valuePropName="checked">
                    <Switch checkedChildren="on" unCheckedChildren="off" />
                  </Form.Item>
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const isPromocodeSwitchOn = getFieldValue(['marketingDetails', 'hasPromocode']);
                      if (!isPromocodeSwitchOn) return null;

                      return (
                        <Form.Item
                          className={styles.promocodesSelect}
                          name={['marketingDetails', 'defaultPromocodeValue']}
                        >
                          <Select placeholder="Promocode">
                            {productPromocodes?.map(item => (
                              <Option key={item.id} data-key={item.id} value={item.promocodeValue}>
                                {item.promocodeValue}
                              </Option>
                            ))}
                            {productPromocodes?.length > 0 && (
                              <Option data-key="n/a" value={null}>
                                N/A
                              </Option>
                            )}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
            )}
            <div>
              <SubTitle>Under construction</SubTitle>
              <div className={styles.toggleWrapper}>
                <Form.Item name={['systemSettings', 'maintenance', 'underMaintenance']} valuePropName="checked">
                  <Switch checkedChildren="on" unCheckedChildren="off" id="maintenance_toggle" />
                </Form.Item>
                <label htmlFor="maintenance_toggle">
                  <Tooltip
                    title={
                      'Page of the product will be substituted with "Under construction" page if the switch is ON.'
                    }
                  >
                    <Icon size={25} name="question-mark-circle-outline" />
                  </Tooltip>
                </label>
              </div>
              <Form.Item className={styles.cards_wrapper} shouldUpdate noStyle>
                {({ getFieldValue, setFieldValue }) => {
                  const underMaintenance = getFieldValue(['systemSettings', 'maintenance', 'underMaintenance']);
                  const isDisplayNow = getFieldValue(['systemSettings', 'maintenance', 'displayNow']);
                  const startDate = getFieldValue(['systemSettings', 'maintenance', 'startDate']);
                  const endDate = getFieldValue(['systemSettings', 'maintenance', 'endDate']);

                  return (
                    underMaintenance && (
                      <>
                        <Form.Item
                          name={['systemSettings', 'maintenance', 'sectionTitle']}
                          rules={[
                            {
                              required: true,
                              message: 'Title is required',
                              whitespace: true,
                            },
                            {
                              max: 255,
                              message: 'Title should be at most 255 characters',
                            },
                          ]}
                        >
                          <DebouncedInputWrapper
                            element={Input}
                            label="Title"
                            addonAfter={
                              <Tooltip title="This text will be displayed as a main message on the page of the product.">
                                <Icon size={35} name="question-mark-circle-outline" />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                        <div className={styles.textAreaWrapper}>
                          <Form.Item
                            name={['systemSettings', 'maintenance', 'description']}
                            rules={[
                              {
                                max: 255,
                                message: 'Description should be at most 255 characters',
                              },
                            ]}
                          >
                            <DebouncedInputWrapper element={Textarea} label="Description" />
                          </Form.Item>
                          <Tooltip title="This text is optional extra info about the product status.">
                            <Icon name="question-mark-circle-outline" />
                          </Tooltip>
                        </div>
                        <Form.Item name={['systemSettings', 'maintenance', 'displayNow']} initialValue={true}>
                          <RadioGroup
                            className={styles.displayNowWrapper}
                            options={[
                              { value: true, label: 'Display now' },
                              { value: false, label: 'Schedule displaying' },
                            ]}
                          />
                        </Form.Item>
                        {!isDisplayNow && (
                          <>
                            <div className={styles.dateTimeBlockItem}>
                              <Form.Item
                                shouldUpdate
                                name={['systemSettings', 'maintenance', 'startDate']}
                                normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                                className={styles.datePicker}
                                rules={[{ required: !isDisplayNow, message: 'Start date is required' }]}
                              >
                                <DatePicker
                                  className="startDate"
                                  format="DD MMMM YYYY HH:mm:ss"
                                  showTime
                                  onChange={() => {
                                    if (endDate) {
                                      setFieldValue(['systemSettings', 'maintenance', 'endDate'], null);
                                    }
                                  }}
                                  label="Start Date"
                                  showNow={false}
                                  disabledDate={disabledStartDate}
                                  suffixIcon={<Icon name="calendar-outline" />}
                                  renderExtraFooterWithCallback={datePickerNewFooter}
                                />
                              </Form.Item>
                              <Tooltip title="Please use local/capital time zone">
                                <Icon size={25} name="question-mark-circle-outline" />
                              </Tooltip>
                            </div>

                            <div className={styles.dateTimeBlockItem}>
                              <Form.Item
                                name={['systemSettings', 'maintenance', 'endDate']}
                                className={styles.datePicker}
                                normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                                rules={[{ required: !isDisplayNow, message: 'End date is required' }]}
                              >
                                <DatePicker
                                  showTime
                                  allowClear
                                  label="End Date"
                                  format="DD MMMM YYYY HH:mm:ss"
                                  className="endDate"
                                  showNow={false}
                                  disabledDate={date => disabledEndDates(date, startDate)}
                                  suffixIcon={<Icon name="calendar-outline" />}
                                  value={endDate}
                                  renderExtraFooterWithCallback={datePickerNewFooter}
                                />
                              </Form.Item>
                              <Tooltip title="Please use local/capital time zone">
                                <Icon size={25} name="question-mark-circle-outline" />
                              </Tooltip>
                            </div>
                          </>
                        )}
                      </>
                    )
                  );
                }}
              </Form.Item>
            </div>
            <div>
              <SubTitle>Hide the product</SubTitle>
              <div className={styles.toggleWrapper}>
                <Form.Item name={['systemSettings', 'hidden']} valuePropName="checked" className={styles.toggleWrapper}>
                  <Switch checkedChildren="on" unCheckedChildren="off" id="hidden_toggle" />
                </Form.Item>
                <label htmlFor="hidden_toggle">
                  <Tooltip title="The product will be hidden in the related category, fast access and info on another product pages about products in the same category if the switch is ON.">
                    <Icon size={25} name="question-mark-circle-outline" />
                  </Tooltip>
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </CollapsiblePanel>
  );
}
