import { useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { AdminInsuranceProviderDto, InsuranceProviderDto, admin } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Icon, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { PROMPT_MESSAGE } from 'shared/constants';
import { onFormFinishFailed, useAdminLocation, usePrompt } from 'shared/utils';

import InformationAboutInsurancePartner from '../features/insurance-partner-landing/componentns/InformationAboutInsurancePartner';
import { InsurancePartnerSystemInfo } from '../features/insurance-partner-landing/componentns/InsurancePartnerSystemInfo';

import styles from './styles.module.scss';
import { useLocationValidation } from 'shared/utils/useLocationValidation';

const InsurancePartnerLandingPage: FC = () => {
  const { partnerId = '' } = useParams();
  const { adminLocation } = useAdminLocation();
  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [formPartner] = Form.useForm<InsuranceProviderDto>();
  const { validateLocationId } = useLocationValidation();

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  const { data: partner, loading: partnerLoading = [] } = useRequest(
    async () => {
      const result = await admin.getProvidersByIds([partnerId]);
      return result[0];
    },
    {
      refreshDeps: [adminLocation?.id, partnerId],
      onSuccess: response => {
        formPartner.setFieldsValue(response);
        validateLocationId(response.locationId, adminLocation.id);
      },
    },
  );

  const { runAsync: updatePartner } = useRequest(
    async (payload: { insuranceProviderId: string; partnerDetails: AdminInsuranceProviderDto }) =>
      admin.updateInsuranceProvider(payload),
    {
      manual: true,
      onSuccess: () => {
        notification.success({
          message: 'Information about Insurance partner has been successfully updated',
        });
      },
    },
  );

  const formFinished = async () => {
    if (!partner) return;
    try {
      const productData = await formPartner.validateFields();

      setShowLeaveModal(false);
      await updatePartner({
        insuranceProviderId: partnerId,
        partnerDetails: {
          title: productData.title,
          insuranceProviderSection: productData.insuranceProviderSection,
          files: {
            ...(productData?.logoMetadata?.content && {
              logo: {
                mimeType: productData?.logoMetadata.mimeType ?? 'image/svg+xml',
                content: productData?.logoMetadata?.content?.split('base64,')[1] ?? '',
              },
            }),
            ...(productData?.logoWhiteMetadata?.content && {
              logoWhite: {
                mimeType: productData?.logoWhiteMetadata.mimeType ?? 'image/svg+xml',
                content: productData?.logoWhiteMetadata?.content?.split('base64,')[1] ?? '',
              },
            }),
          },
          seoAltTag: productData.seoAltTag ?? '',
          seoAltTagWhite: productData.seoAltTagWhite ?? '',
        },
      });
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title={partner?.title ?? 'Loading...'} subTitle="Edit Insurance Partner" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Spin spinning={!!partnerLoading} indicator={<Icon name="sync-outline" />}>
              <Form
                name="partner"
                form={formPartner}
                onFinishFailed={onFormFinishFailed}
                onValuesChange={() => {
                  !isShowLeaveModal && setShowLeaveModal(true);
                }}
                preserve={false}
                initialValues={partner}
              >
                <InsurancePartnerSystemInfo />
                <InformationAboutInsurancePartner />
              </Form>
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Button type="primary" htmlType="submit" onClick={formFinished}>
                Save
              </Button>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};
export default InsurancePartnerLandingPage;
