import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';

import styles from './PolicyDetails.module.scss';
import { uploadFileUrl } from '@saturn/api';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const PolicyDetails = ({ header }: { header: string }) => {
  const policyDetailsContentBannerInlineBody = useImportedInlineBody([
    'policyDetailsContent',
    'banner',
    'fileMetadata',
  ]);
  return (
    <CollapsiblePanel header={header || 'Refer now'} name={['policyDetailsContent']}>
      <div className={styles.content}>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isSectionVisible = getFieldValue(['policyDetailsContent', 'visible']);
            return (
              <Form.Item
                name={['policyDetailsContent', 'banner', 'fileMetadata']}
                rules={[
                  {
                    required: isSectionVisible,
                    message: 'Image is required',
                  },
                ]}
              >
                <ImageUploader
                  inlineBody={policyDetailsContentBannerInlineBody}
                  content="Upload background image"
                  tooltip="The recommended resolution for the image is 1220x460 pixels, the size — up to 300KB"
                  valueFormatter={fileUrlFormatter}
                  action={uploadFileUrl('policyDetailsContent_banner_image')}
                  customRequest={onCustomRequest}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <div>
          <Form.Item
            name={['policyDetailsContent', 'banner', 'title']}
            rules={[
              {
                required: true,
                message: 'Title is required!',
              },
              {
                max: 255,
                message: 'Title should be at most 255 characters',
              },
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Title" />
          </Form.Item>
          <Form.Item
            name={['policyDetailsContent', 'banner', 'subtitle']}
            rules={[
              {
                required: true,
                message: 'Subtitle is required!',
              },
              {
                max: 255,
                message: 'Subtitle should be at most 255 characters',
              },
            ]}
          >
            <DebouncedInputWrapper element={Textarea} label="Subtitle" />
          </Form.Item>
          <Form.Item
            name={['policyDetailsContent', 'banner', 'url']}
            rules={[
              {
                type: 'url',
                message: 'Link is not valid',
              },
              {
                max: 255,
                message: 'Link should be at most 255 characters',
              },
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Link (optional)" />
          </Form.Item>
        </div>
      </div>
    </CollapsiblePanel>
  );
};
