import { useMemo } from 'react';
import type { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import cn from 'classnames';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import collapse from 'remark-collapse';
import gfm from 'remark-gfm';
import headingId from 'remark-heading-id';

import { ExtendedLink } from '@saturn/uikit';

import type { MarkdownProps } from './models';
import { rehypeExtendedTable } from './plugins/rehype-extended-table';

import styles from './Markdown.module.scss';

export const Markdown: FC<MarkdownProps> = ({ content, className, isTableTransformedUpperCase = true }) => {
  const modifiedContent = useMemo(() => content?.replace(/\\n/gi, '\n'), [content]);

  return (
    <ReactMarkdown
      className={cn(className, 'saturn-markdown', styles.common, {
        [styles.textTransformed]: isTableTransformedUpperCase,
      })}
      remarkPlugins={[/*[collapse, { test: '.*' }]*/ gfm, headingId]}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rehypePlugins={[
        // @ts-ignore
        rehypeRaw,
        rehypeExtendedTable,
        [
          // @ts-ignore
          rehypeSanitize,
          {
            ...defaultSchema,
            attributes: { ...defaultSchema.attributes, a: ['href', 'className'] },
          },
        ],
      ]}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        a: function MarkdownLink({ href, className, node, ...rest }) {
          const MAX_LINK_TEXT_LENGTH = 15;
          const { children } = rest;
          const modifiedChildren = children?.map(c => {
            if (
              typeof c === 'string' &&
              (className?.includes('ant-btn') || className?.includes('ant-btn-default')) &&
              c.length > MAX_LINK_TEXT_LENGTH
            ) {
              return `${c.slice(0, MAX_LINK_TEXT_LENGTH)}...`;
            }
            return c;
          });
          return (
            <ExtendedLink
              className={className}
              href={href?.startsWith('#') ? href.replace('#', '#user-content-') : href || ''}
              target={href?.startsWith('#') ? '_self' : '_blank'}
              {...rest}
            >
              {modifiedChildren}
            </ExtendedLink>
          );
        },
        // for vivocha script init in admin panel
        div: ({ id, children, ...props }) => (
          <div id={id === 'user-content-vivocha' ? 'vivocha' : id} {...props}>
            {children}
          </div>
        ),
      }}
    >
      {modifiedContent}
    </ReactMarkdown>
  );
};
