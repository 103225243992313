import type { FC } from 'react';

import { LanguageTabs } from 'shared/components';

import { ContactContent } from '../ContactContent/ContactContent';
import { PolicyDetails } from '../PolicyDetails/PolicyDetails';
import { PolicyDetailsTabsProps } from './models';

export const PolicyDetailsTabs: FC<PolicyDetailsTabsProps> = ({ isShowLeaveModal }) => {
  return (
    <LanguageTabs isShowLeaveModal={isShowLeaveModal}>
      <PolicyDetails header="Refer now" />
      <ContactContent header="Still have questions?" />
    </LanguageTabs>
  );
};
