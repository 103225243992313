import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRequest } from 'ahooks';

import { CreateUserDto, EMAIL_RULE, ROLE_ADMIN, ROLE_SUPER_ADMIN, SUPPORTED_ROLE, admin } from '@saturn/api';
import { Button, Form, Input, Rule, Select, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { ALL_ROLES, ROLES_ADMIN_CM } from 'shared/constants';
import { prepareOptions, useAdminLocation, useAuth } from 'shared/utils';

import styles from './styles.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

const lengthRule = {
  max: 255,
  message: 'Should be at most 255 characters',
};

interface CreateUserFormInterface extends Omit<CreateUserDto, 'roles'> {
  role: SUPPORTED_ROLE;
}

const UsersCreatePage: FC = () => {
  const navigate = useNavigate();
  const { locations } = useAdminLocation();
  const { locationId } = useParams();
  const [form] = Form.useForm<CreateUserFormInterface>();
  const [isLocationDisabled, setLocationDisabled] = useState<boolean>(false);
  const { user } = useAuth();

  const { run: createUser, loading } = useRequest(admin.createUser, {
    manual: true,
    onSuccess: () => {
      notification.success({
        key: 'User has been created!',
        duration: 10,
        message: 'User has been successfully created!',
      });
      navigate(`/${locationId}/users`);
    },
  });

  const onFormFinish = (values: CreateUserFormInterface) => {
    try {
      const { role, ...userData } = values;
      createUser({
        ...userData,
        roles: [role],
      });
    } catch (e) {
      notification.error({
        duration: 10,
        message: 'User creation failed',
      });
    }
  };

  const emailValidator = useCallback((_: Rule, value: string) => {
    if (!value) {
      return Promise.resolve();
    }

    const isEmail = value.match(new RegExp(`^${EMAIL_RULE}$`, 'gi'));

    return !isEmail ? Promise.reject(new Error(`${value} is not a valid email address`)) : Promise.resolve();
  }, []);

  const onRoleChange = (value: string) => {
    if (value === ROLE_SUPER_ADMIN) {
      form.setFieldsValue({ locationIds: locations.map(loc => loc.id) });
      setLocationDisabled(true);
    } else {
      form.setFieldsValue({ locationIds: undefined });
      setLocationDisabled(false);
    }
  };

  return (
    <main>
      <PageHeader title="Create New User" />
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={onFormFinish}
          onFinishFailed={() => console.log('Finish Failed')}
          preserve={false}
          className={styles.formWrapper}
        >
          <Form.Item
            name="firstName"
            normalize={(value: string) => value?.trim()}
            rules={[
              {
                required: true,
                message: 'First Name is required',
              },
              lengthRule,
            ]}
          >
            <DebouncedInputWrapper element={Input} label="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            normalize={(value: string) => value && value.trim()}
            rules={[
              {
                required: true,
                message: 'Last Name is required',
              },
              lengthRule,
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            normalize={(value: string) => value && value.trim()}
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              { validator: emailValidator },
              lengthRule,
            ]}
          >
            <DebouncedInputWrapper element={Input} label="Email" />
          </Form.Item>
          <Form.Item
            name="role"
            rules={[
              {
                required: true,
                message: 'Role is required',
              },
            ]}
          >
            <Select placeholder="Select Role" onChange={onRoleChange}>
              {user?.roles.includes(ROLE_SUPER_ADMIN)
                ? prepareOptions(ALL_ROLES)
                : user?.roles.includes(ROLE_ADMIN)
                  ? prepareOptions(ROLES_ADMIN_CM)
                  : null}
            </Select>
          </Form.Item>
          <Form.Item
            name="locationIds"
            rules={[
              {
                required: true,
                message: 'Location is required',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select Location"
              disabled={isLocationDisabled}
              options={locations.map(({ id, name }) => ({ value: id, label: name }))}
            />
          </Form.Item>
          <Form.Item colon={false}>
            <Button type="primary" htmlType="submit">
              Create User
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </main>
  );
};

export default UsersCreatePage;
