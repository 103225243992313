import {
  ContentBlockProps,
  ContentBlockContainer,
  CollapsiblePanel,
  IconsGallery,
  MarkdownTip,
} from 'shared/components';
import styles from './QuoteNowProductBlock.module.scss';
import QuoteNowProductBenefitBlock from './QuoteNowProductBenefitBlock';
import { Form, Select, Option, Textarea } from '@saturn/uikit';
import { useRequest } from 'ahooks';
import { admin, PRODUCT_TYPE_API } from '@saturn/api';
import { useAdminLocation } from 'shared/utils';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';
import { useParams } from 'react-router-dom';
import { QuoteNowDto } from '@saturn/api/src/models/dto/QuoteNowDto';

export default function QuoteNowProductBlock({ required, ...fieldProps }: ContentBlockProps) {
  const { categoryId = '' } = useParams();
  const blockName = fieldProps.name;

  const { adminLocation } = useAdminLocation();
  const { id: locationId } = adminLocation;

  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const watchedProducts = Form.useWatch([...fieldProps.parentName]);

  const filteredProducts = locationProducts.filter(product => {
    const productFromThisCategory = product.categoryId === categoryId;
    const apiProduct = product.type == PRODUCT_TYPE_API;
    return productFromThisCategory && apiProduct;
  });
  const disabledProductsIds = watchedProducts
    ?.map((item?: QuoteNowDto['products'][number]) => item?.productId)
    .filter(Boolean) as string[];

  return (
    <div>
      <Form.Item
        {...fieldProps}
        preserve={false}
        valuePropName="icon"
        name={[blockName, 'thumbnailId']}
        rules={[
          {
            required: required,
            message: 'Thumbnail is required',
          },
        ]}
      >
        <IconsGallery />
      </Form.Item>
      <Form.Item
        name={[blockName, 'productId']}
        rules={[
          {
            required,
            message: 'Product is required',
          },
        ]}
      >
        <Select placeholder="Product" loading={loadingProducts}>
          {filteredProducts?.map(item => (
            <Option
              disabled={disabledProductsIds?.some(productId => productId === item.id)}
              key={item.id}
              data-key={item.id}
              value={item.id}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <MarkdownTip>
        <Form.Item
          name={[blockName, 'description']}
          rules={[
            {
              required,
              message: 'Description is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    min: 1,
                    message: 'Description should be at least 1 character',
                  },
                  {
                    max: 1000,
                    message: 'Description should be at most 1000 characters',
                  },
                ]
              : []),
          ]}
        >
          <DebouncedInputWrapper element={Textarea} label="Description" />
        </Form.Item>
      </MarkdownTip>
      <CollapsiblePanel withoutSwitch header="Benefits" name={[blockName, 'benefits']}>
        <div className={styles.paddedContent}>
          <ContentBlockContainer
            name={[blockName, 'benefits']}
            maxItems={3}
            title="Benefit"
            addButtonText="Add Benefit"
            showChangePositionButton={true}
            isCollapsible
          >
            {props => <QuoteNowProductBenefitBlock {...props} required={required} />}
          </ContentBlockContainer>
        </div>
      </CollapsiblePanel>
    </div>
  );
}
