/* eslint-disable sonarjs/no-identical-functions */
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

import { admin, getLocationTimezone } from '@saturn/api';
import {
  PromocodeBasic,
  PromocodeDetails,
  PromocodeV3Dto,
  PromocodeBenefitV3,
} from '@saturn/api/src/models/dto/PromocodeDto';
import { formatPayloadForPromocodeApi } from '@saturn/api/src/utils/timezone.utils';
import { Button, Card, Form, FormSubmitFailed, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { PromocodeInfo } from '../features/promocodes/PromocodeInfo/PromocodeInfo';
import { PromocodeInfoV3 } from '../features/promocodes/PromocodeInfo/PromocodeInfoV3';
import { PromotionBenefits } from '../features/promocodes/PromotionBenefits/PromotionBenefits';
import { PromotionBenefitsV3 } from '../features/promocodes/PromotionBenefits/PromotionBenefitsV3';

import styles from './styles.module.scss';
import cn from 'classnames';
import { useLocationValidation } from 'shared/utils/useLocationValidation';
import { hasPromocodeValueChanged } from '../shared/utils/validations';
import { useFeaturesContext } from 'shared/contexts/FeaturesContext';

const PromocodeCreatePage: FC = () => {
  const { isFeatureEnabled, featuresLoading } = useFeaturesContext();
  const arePromocodesV3 = isFeatureEnabled('cms-promocode-v3-enabled');

  if (featuresLoading) {
    return null;
  }
  if (arePromocodesV3) {
    return <PromocodeCreatePageV3 />;
  }
  return <OldPromocodeCreatePage />;
};

const PromocodeCreatePageV3: FC = () => {
  const { promocodeId = '' } = useParams();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeV3Dto>();
  const [formDetails] = Form.useForm<PromocodeBenefitV3>();
  const { validateLocationId } = useLocationValidation();

  const onError = () => {
    notification.error({
      message: 'Error during promocode update has happened',
    });
  };

  const locationTimezone = getLocationTimezone(adminLocation.id);

  const { data: promocodeInfo, loading: promocodeLoading } = useRequest(() => admin.getPromocodeV3(promocodeId), {
    refreshDeps: [promocodeId],
    onSuccess: data => {
      if (data) {
        const zonedStartDate = data.startDate ? dayjs(data.startDate).utcOffset(locationTimezone) : undefined;
        const zonedEndDate = data.endDate ? dayjs(data.endDate).utcOffset(locationTimezone) : undefined;
        form.setFieldsValue({ ...data, startDate: zonedStartDate, endDate: zonedEndDate });
        validateLocationId(data.locationId, adminLocation.id);
      }
    },
    onError,
  });

  const { data: promocodeBenefits, loading: promocodeBenefitsLoading } = useRequest(
    () => admin.getPromocodeBenefitsV3(promocodeId, locationLanguage),
    {
      refreshDeps: [promocodeId, locationLanguage],
      onSuccess: data => {
        if (data) {
          formDetails.setFieldsValue(data);
          // the problem is in ContentBlockContainer it does need list to be present to actually show the data in form we need copy because in the set function something can be mutating values in place
          setTimeout(() => {
            formDetails.setFieldsValue(data);
          }, 100);
        }
      },
      onError,
    },
  );

  const { data: locationPromocodes = [] } = useRequest(
    async () => {
      return await admin.getPromocodesV3(adminLocation.id);
    },
    {
      refreshDeps: [adminLocation.id],
    },
  );
  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts
    .filter(p => p.type === 'API')
    .filter(product => {
      if (promocodeInfo?.productId === product.id) {
        return true;
      }
      return !locationPromocodes.some(promocode => promocode.productId === product.id);
    });

  const { runAsync: savePromocode } = useRequest(admin.savePromocodeV3, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefitsV3, { manual: true });

  const formFinished = async () => {
    try {
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const promocodePayload = await savePromocode({
        id: promocodeId,
        productId: promocodeData.productId,
        defaultValue: promocodeData.defaultValue,
        startDate: promocodeData.startDate,
        endDate: promocodeData.endDate,
        active: promocodeData.active,
        displayNow: promocodeData.displayNow,
        locationId,
        promocodeBenefits: [],
      }).catch(onError);
      if (promocodePayload?.id) {
        const mappedBenefits = promocodeDetails.promocodeBenefits?.map(benefit => {
          return {
            ...benefit,
            language: locationLanguage as string,
          };
        });
        await savePromocodeBenefits(promocodePayload.id, locationLanguage as string, {
          promocodeBenefits: mappedBenefits,
        });
        notification.success({
          message: 'Promocode has been successfully updated',
        });
      }
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Setup campaign" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={cn(styles.content, styles.promocodeContent)}>
            <Spin spinning={promocodeLoading || promocodeBenefitsLoading}>
              <Form name="promocode" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
                <PromocodeInfoV3 products={apiProducts} loadingProducts={loadingProducts} />
              </Form>
              {promocodeBenefits && (
                <Form
                  name="promocodeDetails"
                  form={formDetails}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  onValuesChange={changedField => {
                    if (hasPromocodeValueChanged(changedField)) {
                      formDetails.validateFields();
                    }
                  }}
                  initialValues={promocodeBenefits}
                >
                  <PromotionBenefitsV3 />
                </Form>
              )}
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

const OldPromocodeCreatePage: FC = () => {
  const { promocodeId = '' } = useParams();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeBasic>();
  const [formDetails] = Form.useForm<PromocodeDetails>();

  const onError = () => {
    notification.error({
      message: 'Error during promocode creation has happened',
    });
  };

  const { data: promocodeInfo, loading: promocodeLoading } = useRequest(() => admin.getPromocode(promocodeId), {
    refreshDeps: [promocodeId],
    onSuccess: data => {
      if (data) {
        // Map DTO to form fields and apply market timezone
        const locationTimezone = getLocationTimezone(adminLocation.id);

        const zonedExpiryDate = data.expiredDate ? dayjs(data.expiredDate).utcOffset(locationTimezone) : null;
        const zonedStartDate = data.startDate ? dayjs(data.startDate).utcOffset(locationTimezone) : null;

        form.setFieldsValue({
          isStartNow: data.isStartNow,
          isNoExpired: data.isNoExpired,
          promocodeValue: data.promocodeValue,
          productId: data.productId,
          startDate: zonedStartDate?.format('YYYY-MM-DD') ?? null,
          startTime: zonedStartDate ?? null,
          expiryDate: zonedExpiryDate?.format('YYYY-MM-DD') ?? null,
          expiryTime: zonedExpiryDate ?? null,
        });
      }
    },
    onError,
  });

  const { data: promocodeBenefits, loading: promocodeBenefitsLoading } = useRequest(
    () => admin.getPromocodeBenefits(promocodeId, locationLanguage),
    {
      refreshDeps: [promocodeId, locationLanguage],
      onSuccess: data => {
        if (data) {
          formDetails.setFieldsValue(data);
        }
      },
      onError,
    },
  );

  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts.filter(p => p.type === 'API');

  const { runAsync: savePromocode } = useRequest(admin.savePromocode, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefits, { manual: true });

  const formFinished = async () => {
    try {
      if (!promocodeInfo) return;
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const promocodesWithFormattedTimeFields = formatPayloadForPromocodeApi({
        isActive: promocodeInfo.isActive,
        promocodeData,
        language: locationLanguage as string,
        id: promocodeId,
        locationId,
      });
      const mapedBenefits: PromocodeDetails = {
        benefits: promocodeDetails.benefits,
      };

      await savePromocode(promocodesWithFormattedTimeFields);
      await savePromocodeBenefits(promocodeId, locationLanguage as string, mapedBenefits);

      notification.success({
        message: 'Promocode has been successfully updated',
      });
    } catch (error) {
      onFormFinishFailed(error as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create Promocodes" subTitle={`Edit Promocode: ${promocodeInfo?.promocodeValue}`} />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Spin spinning={promocodeLoading || promocodeBenefitsLoading}>
              <Form name="promocode" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
                <PromocodeInfo products={apiProducts} loadingProducts={loadingProducts} />
              </Form>
              {promocodeBenefits && (
                <Form
                  name="promocodeDetails"
                  form={formDetails}
                  onFinishFailed={onFormFinishFailed}
                  preserve={false}
                  initialValues={promocodeBenefits}
                >
                  <PromotionBenefits />
                </Form>
              )}
            </Spin>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default PromocodeCreatePage;
