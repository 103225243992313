import type { FC } from 'react';

import { Form, Textarea } from '@saturn/uikit';

import type { ContentBlockProps } from 'shared/components';
import { MarkdownTip } from 'shared/components';

import styles from '../../StandaloneFAQ.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const StandaloneFAQBlock: FC<ContentBlockProps> = ({ name, required, ...fieldProps }) => {
  return (
    <div className={styles.inputsWrapper}>
      <Form.Item
        {...fieldProps}
        name={[name, 'question']}
        rules={[
          {
            required,
            message: 'Question is required',
            whitespace: true,
          },
          ...(required
            ? [
                {
                  max: 255,
                  message: 'Question should be at most 255 characters',
                },
              ]
            : []),
        ]}
      >
        <DebouncedInputWrapper element={Textarea} label="Question" />
      </Form.Item>
      <MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[name, 'answer']}
          rules={[
            {
              required,
              message: 'Answer is required',
              whitespace: true,
            },
            ...(required
              ? [
                  {
                    max: 10000,
                    message: 'Answer should be at most 10 000 characters',
                  },
                ]
              : []),
          ]}
        >
          <DebouncedInputWrapper element={Textarea} label="Answer" />
        </Form.Item>
      </MarkdownTip>
    </div>
  );
};
