/* eslint-disable sonarjs/no-identical-functions */
import { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import { admin } from '@saturn/api';
import {
  PromocodeBasic,
  PromocodeDetails,
  PromocodeV3Dto,
  PromocodeBenefitV3,
} from '@saturn/api/src/models/dto/PromocodeDto';
import { formatPayloadForPromocodeApi } from '@saturn/api/src/utils/timezone.utils';
import { Button, Card, Form, FormSubmitFailed, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { PromocodeInfo } from '../features/promocodes/PromocodeInfo/PromocodeInfo';
import { PromocodeInfoV3 } from '../features/promocodes/PromocodeInfo/PromocodeInfoV3';
import { PromotionBenefits } from '../features/promocodes/PromotionBenefits/PromotionBenefits';
import { PromotionBenefitsV3 } from '../features/promocodes/PromotionBenefits/PromotionBenefitsV3';

import styles from './styles.module.scss';
import cn from 'classnames';
import { hasPromocodeValueChanged } from '../shared/utils/validations';
import { useFeaturesContext } from 'shared/contexts/FeaturesContext';

const PromocodeCreatePage = () => {
  const { isFeatureEnabled, featuresLoading } = useFeaturesContext();
  const arePromocodesV3 = isFeatureEnabled('cms-promocode-v3-enabled');

  if (featuresLoading) {
    return null;
  }
  if (arePromocodesV3) {
    return <PromocodeCreatePageV3 />;
  }
  return <OldPromocodeCreatePage />;
};
const PromocodeCreatePageV3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeV3Dto>();
  const [formDetails] = Form.useForm<PromocodeBenefitV3>();

  useEffect(() => {
    form.setFieldValue(['displayNow'], true);
  }, []);

  const { data: locationPromocodes = [] } = useRequest(
    async () => {
      return await admin.getPromocodesV3(adminLocation.id);
    },
    {
      refreshDeps: [adminLocation.id],
    },
  );
  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts
    .filter(p => p.type === 'API')
    .filter(product => {
      return !locationPromocodes.some(promocode => promocode.productId === product.id);
    });

  const { runAsync: savePromocode } = useRequest(admin.savePromocodeV3, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefitsV3, { manual: true });

  const onError = () => {
    notification.error({
      message: 'Error during promocode creation has happened',
    });
  };

  const formFinished = async () => {
    try {
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const newPromocode = await savePromocode({
        productId: promocodeData.productId,
        defaultValue: promocodeData.defaultValue,
        startDate: promocodeData.startDate,
        endDate: promocodeData.endDate,
        active: promocodeData.active,
        displayNow: promocodeData.displayNow,
        locationId,
        promocodeBenefits: [],
      }).catch(onError);
      if (newPromocode?.id) {
        const mappedBenefits = promocodeDetails.promocodeBenefits?.map(benefit => {
          return {
            ...benefit,
            language: locationLanguage as string,
          };
        });
        await savePromocodeBenefits(newPromocode.id, locationLanguage as string, { promocodeBenefits: mappedBenefits });
        notification.success({
          message: 'Promocode has been successfully created',
        });
        const [, locationInPath, promocodes] = location.pathname.split('/');
        navigate(`/${locationInPath}/${promocodes}`, { replace: true });
      }
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Setup Campaign" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={cn(styles.content, styles.promocodeContent)}>
            <Form
              name="promocode"
              form={form}
              onFinishFailed={onFormFinishFailed}
              initialValues={{ active: true }}
              preserve={false}
            >
              <PromocodeInfoV3 products={apiProducts} loadingProducts={loadingProducts} />
            </Form>
            <Form
              name="promocodeDetails"
              form={formDetails}
              onValuesChange={changedField => {
                if (hasPromocodeValueChanged(changedField)) {
                  formDetails.validateFields();
                }
              }}
              onFinishFailed={onFormFinishFailed}
              preserve={false}
            >
              <PromotionBenefitsV3 />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

const OldPromocodeCreatePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const { id: locationId } = adminLocation;
  const [form] = Form.useForm<PromocodeBasic>();
  const [formDetails] = Form.useForm<PromocodeDetails>();

  useEffect(() => {
    form.setFieldsValue({
      isStartNow: true,
      isNoExpired: true,
    });
    formDetails.setFieldsValue({
      benefits: [{ planName: '', benefitTitle: '', benefitDescription: '', thumbnailId: '' }],
    });
  }, []);

  const { data: locationProducts = [], loading: loadingProducts } = useRequest(
    () => admin.getProductForLocation(locationId),
    {
      refreshDeps: [locationId],
    },
  );
  const apiProducts = locationProducts.filter(p => p.type === 'API');

  const { runAsync: savePromocode } = useRequest(admin.savePromocode, { manual: true });
  const { runAsync: savePromocodeBenefits } = useRequest(admin.savePromocodeBenefits, { manual: true });

  const onError = () => {
    notification.error({
      message: 'Error during promocode creation has happened',
    });
  };

  // eslint-disable-next-line sonarjs/no-identical-functions
  const formFinished = async () => {
    try {
      const promocodeData = await form.validateFields();
      const promocodeDetails = await formDetails.validateFields();

      const promocodesWithFormattedTimeFields = formatPayloadForPromocodeApi({
        isActive: true,
        promocodeData,
        language: locationLanguage as string,
        locationId,
      });
      const mapedBenefits: PromocodeDetails = {
        benefits: promocodeDetails.benefits,
      };

      const newPromocode = await savePromocode(promocodesWithFormattedTimeFields).catch(onError);
      if (newPromocode && newPromocode.id) {
        // @ts-ignore
        await savePromocodeBenefits(newPromocode.id, locationLanguage as string, mapedBenefits),
          notification.success({
            message: 'Promocode has been successfully created',
          });
        const [, locationInPath, promocodes] = location.pathname.split('/');
        navigate(`/${locationInPath}/${promocodes}`, { replace: true });
      }
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  return (
    <main>
      <PageHeader title="Create Promocodes" subTitle="Create Promocode" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Form name="promocode" form={form} onFinishFailed={onFormFinishFailed} preserve={false}>
              <PromocodeInfo products={apiProducts} loadingProducts={loadingProducts} />
            </Form>
            <Form name="promocodeDetails" form={formDetails} onFinishFailed={onFormFinishFailed} preserve={false}>
              <PromotionBenefits />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Card title="Control" bordered={false} className={styles.sidebarCardWrapper}>
              <Form.Item colon={false}>
                <Button type="primary" htmlType="submit" onClick={formFinished}>
                  Save
                </Button>
              </Form.Item>
            </Card>
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default PromocodeCreatePage;
