import {
  PromocodeBenefitV3,
  PromocodeDetails,
  PromocodeDto,
  PromocodeV3Dto,
  PromocodesDto,
  PromocodesV3Dto,
} from '../../models/dto/PromocodeDto';
import { axiosClient as api } from '../axiosClient';

export async function getPromocodes(locationId: string): Promise<PromocodesDto> {
  const { data } = await api.get<PromocodesDto>(`/admin/promocode/${locationId}/list`);
  return data;
}
export async function getPromocodesV3(locationId: string): Promise<PromocodesV3Dto> {
  const { data } = await api.get<PromocodesV3Dto>(`/admin/v3/promocode/${locationId}/list`);
  return data;
}

export async function deletePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.delete<string>(`/admin/promocode/${promocodeId}`);
  return data;
}
export async function deletePromocodeV3(campaignId: string): Promise<string> {
  const { data } = await api.delete<string>(`/admin/v3/promocode/${campaignId}`);
  return data;
}

export async function enablePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/promocode/${promocodeId}/enable`);
  return data;
}
export async function enablePromocodeV3(campaignId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/v3/promocode/${campaignId}/enable`);
  return data;
}

export async function disablePromocode(promocodeId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/promocode/${promocodeId}/disable`);
  return data;
}

export async function disablePromocodeV3(campaignId: string): Promise<string> {
  const { data } = await api.put<string>(`/admin/v3/promocode/${campaignId}/disable`);
  return data;
}

export async function savePromocode(promocode: PromocodeDto): Promise<PromocodeDto> {
  const { data } = await api.post<PromocodeDto>('/admin/promocode', promocode);
  return data;
}
export async function savePromocodeV3(promocode: Partial<PromocodeV3Dto>): Promise<PromocodeV3Dto> {
  const { data } = await api.post<PromocodeV3Dto>('/admin/v3/promocode', promocode);
  return data;
}

export async function savePromocodeBenefits(
  promocodeId: string,
  language: string,
  benefits: PromocodeDetails,
): Promise<PromocodeDetails> {
  const { data } = await api.post<PromocodeDetails>(`/admin/promocode/${promocodeId}/benefits/${language}`, benefits);
  return data;
}
export async function savePromocodeBenefitsV3(
  promocodeId: string,
  language: string,
  benefits: PromocodeBenefitV3,
): Promise<PromocodeBenefitV3> {
  const { data } = await api.post<PromocodeBenefitV3>(
    `/admin/v3/promocode/${promocodeId}/benefits/${language}`,
    benefits,
  );
  return data;
}

export async function getPromocode(promocodeId: string): Promise<PromocodeDto> {
  const { data } = await api.get<PromocodeDto>(`/admin/promocode/${promocodeId}`);
  return data;
}

export async function getPromocodeV3(promocodeId: string): Promise<PromocodeV3Dto> {
  const { data } = await api.get<PromocodeV3Dto>(`/admin/v3/promocode/${promocodeId}`);
  return data;
}

export async function getPromocodeBenefits(
  promocodeId: string,
  language?: string,
): Promise<PromocodeDetails | undefined> {
  if (!language) {
    return undefined;
  }
  const { data } = await api.get<PromocodeDetails>(`/admin/promocode/${promocodeId}/benefits/${language}`);
  return data;
}
export async function getPromocodeBenefitsV3(
  promocodeId: string,
  language?: string,
): Promise<PromocodeBenefitV3 | undefined> {
  if (!language) {
    return undefined;
  }
  const { data } = await api.get<PromocodeBenefitV3>(`/admin/v3/promocode/${promocodeId}/benefits/${language}`);
  return data;
}
