import type { FC } from 'react';

import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import { ProductDto, getLocationTimezone } from '@saturn/api';
import { DatePicker, Form, Icon, Input, Option, RadioGroup, Select, Switch, Tooltip, Button } from '@saturn/uikit';

import { useAdminLocation, normalizeEmptyStringToNull } from 'shared/utils';

import styles from './PromocodeInfo.module.scss';
import cn from 'classnames';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

const displayNowOptions = [
  { value: true, label: 'Display now Input field for promocode' },
  { value: false, label: 'Schedule displaying of Input field for promocode' },
];

type PromocodeInfoV3Props = {
  products: ProductDto[];
  loadingProducts: boolean;
};
export const PromocodeInfoV3: FC<PromocodeInfoV3Props> = ({ products, loadingProducts }) => {
  const { adminLocation } = useAdminLocation();
  const locationTimezone = getLocationTimezone(adminLocation.id);

  const disabledStartDate = (date: Dayjs) => {
    const minDate = dayjs().utcOffset(locationTimezone).startOf('day');
    const maxDate = dayjs().utcOffset(locationTimezone).add(1, 'month').endOf('day');
    return !date || date.isBefore(minDate) || date.isAfter(maxDate);
  };

  const disabledEndDates = (date: Dayjs | null, startDate?: string) => {
    const customFormat = 'DD-MM-YYYY';
    const formattedStart = startDate && dayjs(startDate)?.format(customFormat);
    const formattedDate = date?.format(customFormat);

    const start = formattedStart
      ? dayjs(formattedStart, customFormat).utcOffset(locationTimezone)
      : dayjs().utcOffset(locationTimezone);

    const minDate = start.clone().add(1, 'day').startOf('day');

    const maxDate = minDate.clone().add(3, 'months').endOf('day');

    const isPastMin = dayjs(formattedDate, customFormat)?.utcOffset(locationTimezone).isBefore(minDate);
    const isPastMax = dayjs(formattedDate, customFormat).utcOffset(locationTimezone).isAfter(maxDate);

    return !date || isPastMin || isPastMax;
  };

  const datePickerNewFooter = (onChange: ((date: Dayjs | null) => void) | undefined) => {
    return (
      <div className={styles.footer}>
        <Button
          onClick={() => {
            const now = dayjs().utcOffset(locationTimezone);
            if (onChange) {
              onChange(now);
            }
          }}
          type="text"
          className={cn(styles.extraPickerBtn, styles.alignLeft)}
        >
          Now
        </Button>
        <Button
          onClick={() => {
            if (onChange) {
              onChange(null);
            }
            const AntClear = document.querySelector('.startDate .ant-picker-clear') as HTMLElement;
            if (AntClear) {
              AntClear?.click();
            }
          }}
          type="text"
          className={styles.extraPickerBtn}
        >
          Clear all
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.content}>
      <Form.Item
        name="productId"
        rules={[
          {
            required: true,
            message: 'Product is required',
          },
        ]}
      >
        <Select placeholder="Product" loading={loadingProducts}>
          {products?.map(item => (
            <Option key={item.id} data-key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="defaultValue"
        normalize={normalizeEmptyStringToNull}
        rules={[
          {
            min: 3,
            message: 'Default value should be at least 3 characters',
          },
          {
            max: 50,
            message: 'Default value should be at most 50 characters',
          },
        ]}
      >
        <DebouncedInputWrapper element={Input} label="Default value to be prepopulated in the input field" />
      </Form.Item>
      <div className={styles.activeCampaignWrapper}>
        <p className={styles.label}>Active campaign</p>
        <Form.Item className={styles.activeCampaignSwitch} name="active" valuePropName="checked">
          <Switch checkedChildren="on" unCheckedChildren="off" />
        </Form.Item>
        <Tooltip title="Enable to turn on promocode immediately after saving changes.">
          <Icon size={25} name="question-mark-circle-outline" />
        </Tooltip>
      </div>
      <Form.Item data-cy="displayNow" name="displayNow">
        <RadioGroup className={styles.displayNowWrapper} options={displayNowOptions} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldValue, setFieldValue }) => {
          const isDisplayNow = getFieldValue(['displayNow']);
          const startDate = getFieldValue(['startDate']);
          const endDate = getFieldValue(['endDate']);

          return (
            <>
              <div className={styles.dateTimeBlockV2}>
                {!isDisplayNow && (
                  <>
                    <div className={styles.dateTimeBlockItem}>
                      <Form.Item
                        shouldUpdate
                        name="startDate"
                        normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                        className={styles.datePicker}
                        rules={[{ required: !isDisplayNow, message: 'Start date is required' }]}
                      >
                        <DatePicker
                          className="startDate"
                          format="DD MMMM YYYY HH:mm:ss"
                          showTime
                          onChange={() => {
                            if (endDate) {
                              setFieldValue(['endDate'], null);
                            }
                          }}
                          label="Start Date"
                          showNow={false}
                          disabledDate={disabledStartDate}
                          suffixIcon={<Icon name="calendar-outline" />}
                          renderExtraFooterWithCallback={datePickerNewFooter}
                        />
                      </Form.Item>
                      <Tooltip title="Please use local/capital time zone">
                        <Icon size={25} name="question-mark-circle-outline" />
                      </Tooltip>
                    </div>

                    <div className={styles.dateTimeBlockItem}>
                      <Form.Item
                        name="endDate"
                        className={styles.datePicker}
                        normalize={date => date && dayjs(date).utcOffset(locationTimezone, true)}
                        rules={[{ required: !isDisplayNow, message: 'End date is required' }]}
                      >
                        <DatePicker
                          showTime
                          allowClear
                          label="End Date"
                          format="DD MMMM YYYY HH:mm:ss"
                          className="endDate"
                          showNow={false}
                          disabledDate={date => disabledEndDates(date, startDate)}
                          suffixIcon={<Icon name="calendar-outline" />}
                          value={endDate}
                          renderExtraFooterWithCallback={datePickerNewFooter}
                        />
                      </Form.Item>
                      <Tooltip title="Please use local/capital time zone">
                        <Icon size={25} name="question-mark-circle-outline" />
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};
