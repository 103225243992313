import type { FC } from 'react';

import { Form, Input, Textarea } from '@saturn/uikit';
import type { RuleType } from '@saturn/uikit';

import { MarkdownTip } from 'shared/components';

import { CollapsiblePanel } from '../CollapsiblePanel/CollapsiblePanel';

import styles from './ReferFriend.module.scss';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

type ReferFriendProps = {
  header?: string;
};

export const ReferFriend: FC<ReferFriendProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'Refer a friend'} name={['referFriend']} isRequiredSectionTitle={false}>
      <div className={styles.inputsWrapper}>
        <Form.Item
          shouldUpdate={(prevValue, newValue) => prevValue.referFriend.visible !== newValue.referFriend.visible}
        >
          {({ getFieldValue, setFieldValue }) => {
            const isFieldRequired = getFieldValue(['referFriend', 'visible']);
            setFieldValue(['referFriend', 'ctaButton', 'visible'], isFieldRequired);

            return (
              <>
                <Form.Item
                  name={['referFriend', 'title']}
                  rules={[
                    {
                      required: isFieldRequired,
                      message: 'Refer a friend title is required',
                      whitespace: true,
                    },
                    ...(isFieldRequired
                      ? [
                          {
                            min: 1,
                            message: 'Refer a friend title should be at least 1 character',
                          },
                          {
                            max: 100,
                            message: 'Refer a friend title should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Title" />
                </Form.Item>
                <MarkdownTip>
                  <div className={styles.textArea_wrapper}>
                    <Form.Item
                      name={['referFriend', 'description']}
                      className={styles.formItem}
                      rules={[
                        ...(isFieldRequired
                          ? [
                              {
                                min: 1,
                                message: 'Refer a friend description should be at least 1 character',
                              },
                              {
                                max: 255,
                                message: 'Refer a friend description should be at most 255 characters',
                              },
                            ]
                          : []),
                      ]}
                    >
                      <DebouncedInputWrapper element={Textarea} label="Description" />
                    </Form.Item>
                  </div>
                </MarkdownTip>
                <Form.Item hidden name={['referFriend', 'ctaButton', 'visible']}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['referFriend', 'ctaButton', 'title']}
                  rules={[
                    {
                      required: isFieldRequired,
                      message: 'Refer a friend button title is required',
                      whitespace: true,
                    },
                    ...(isFieldRequired
                      ? [
                          {
                            min: 1,
                            message: 'Refer a friend button title should be at least 1 character',
                          },
                          {
                            max: 20,
                            message: 'Refer a friend button title should be at most 20 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Link / Button title" />
                </Form.Item>
                <Form.Item
                  name={['referFriend', 'ctaButton', 'url']}
                  rules={[
                    {
                      required: isFieldRequired,
                      message: 'Refer a friend URL is required',
                      whitespace: true,
                    },

                    ...(isFieldRequired
                      ? [
                          {
                            type: 'url' as RuleType,
                            message: 'Refer a friend URL is invalid',
                          },
                          {
                            max: 255,
                            message: 'Refer a friend URL should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="URL" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};
