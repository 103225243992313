import type { FC } from 'react';

import cn from 'classnames';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Textarea } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import { CallbackWidgetProps } from './model';

import styles from './CallbackWidget.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const CallbackWidget: FC<CallbackWidgetProps> = ({ header }) => {
  const name = ['callbackWidgetSection'];
  const callbackWidgetInlineBody = useImportedInlineBody([...name, 'fileMetadata']);

  return (
    <CollapsiblePanel header={header || 'Callback Widget'} name={['callbackWidgetSection']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue([...name, 'visible']);
          return (
            <div className={cn(styles.wrapper)}>
              <Form.Item
                name={[...name, 'fileMetadata']}
                rules={[
                  {
                    required: isFieldRequired,
                    message: 'Image is required',
                  },
                ]}
              >
                <ImageUploader
                  inlineBody={callbackWidgetInlineBody}
                  content="Upload Image"
                  tooltip="Recommended dimensions are 950*580px"
                  className={styles.image_container}
                  valueFormatter={fileUrlFormatter}
                  action={uploadFileUrl(`cb_widget_image_${name}`)}
                  customRequest={onCustomRequest}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
              <Form.Item
                name={[...name, 'subTitle']}
                rules={[
                  {
                    required: isFieldRequired,
                    message: 'Subtitle is required',
                    whitespace: true,
                  },
                  ...(isFieldRequired
                    ? [
                        {
                          max: 255,
                          message: 'Subtitle should be at most 255 characters',
                        },
                      ]
                    : []),
                ]}
              >
                <DebouncedInputWrapper element={Textarea} label="Subtitle" />
              </Form.Item>
            </div>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
