import { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, VisibilityChangeListener } from 'shared/components';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import useImportedInlineBody from '../../../../shared/hooks/useImportedInlineBody';
import { FastInsuranceProps } from './model';

import styles from './FastInsurance.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const FastInsurance: FC<FastInsuranceProps> = ({ header }) => {
  const fastInsuranceImageName = ['fastInsurance', 'bannerImgMetadata'];
  const fastInsuranceImageInline = useImportedInlineBody(fastInsuranceImageName);

  return (
    <CollapsiblePanel header={header || 'Fast Insurance'} name={['fastInsurance']}>
      <div className={styles.content}>
        <VisibilityChangeListener section="fastInsurance">
          {required => (
            <div className={styles.wrapper}>
              <Form.Item
                name={fastInsuranceImageName}
                rules={[
                  {
                    required,
                    message: 'Banner Image is required',
                  },
                ]}
              >
                <ImageUploader
                  inlineBody={fastInsuranceImageInline}
                  content="Upload Banner Image"
                  tooltip="This image will appear on the Fast Insurance block."
                  className={styles.image_container}
                  action={uploadFileUrl('banner_image')}
                  customRequest={onCustomRequest}
                  valueFormatter={fileUrlFormatter}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
              <div className={styles.inputsWrapper}>
                <Form.Item
                  name={['fastInsurance', 'title']}
                  rules={[
                    {
                      required,
                      message: 'Title is required',
                      whitespace: true,
                    },
                    ...(required
                      ? [
                          {
                            max: 100,
                            message: 'Title should be at most 100 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Title" />
                </Form.Item>
                <div className={styles.textArea_wrapper}>
                  <Form.Item
                    className={styles.formItem}
                    name={['fastInsurance', 'description']}
                    rules={[
                      {
                        required,
                        message: 'Description is required',
                        whitespace: true,
                      },
                      ...(required
                        ? [
                            {
                              max: 255,
                              message: 'Description should be at most 255 characters',
                            },
                          ]
                        : []),
                    ]}
                  >
                    <DebouncedInputWrapper element={Textarea} label="Description" />
                  </Form.Item>
                </div>
                <Form.Item
                  name={['fastInsurance', 'seoTags']}
                  rules={[
                    ...(required
                      ? [
                          {
                            max: 255,
                            message: 'Should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Alt Tag for SEO" />
                </Form.Item>
              </div>
            </div>
          )}
        </VisibilityChangeListener>
      </div>
    </CollapsiblePanel>
  );
};
