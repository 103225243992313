import type { FC } from 'react';

import { Form, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { StandaloneFAQBlock } from './components/StandaloneFAQBlock/StandaloneFAQBlock';

import styles from './StandaloneFAQ.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export const StandaloneFAQ: FC<{ header?: string }> = ({ header }) => {
  const sectionName = ['standaloneFaq'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel
        sectionTitleLabel="Section Title"
        header={header || 'Standalone FAQs'}
        name={sectionName}
        showSectionTitle
      >
        <ContentBlockContainer
          className={styles.content}
          maxItems={20}
          name={[...sectionName, 'questions']}
          addButtonText="Add question"
          title="Question"
          showChangePositionButton={true}
        >
          {props => <StandaloneFAQBlock {...props} />}
        </ContentBlockContainer>
        <Form.Item
          name={[...sectionName, 'faqDisclosure']}
          rules={[
            {
              max: 510,
              message: 'FAQ Disclosure should be at most 510 characters',
            },
          ]}
        >
          <DebouncedInputWrapper element={Textarea} label="FAQ Disclosure" />
        </Form.Item>
      </CollapsiblePanel>
    </div>
  );
};
