import type { FC } from 'react';

import { Alert } from 'antd';

import { Form, Icon, Input } from '@saturn/uikit';
import type { RuleType } from '@saturn/uikit';

import { CollapsiblePanel } from 'shared/components';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

export interface BreadcrumbProps {
  header?: string;
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ header }) => {
  return (
    <CollapsiblePanel
      header={header || 'Breadcrumb'}
      name={['breadCrumb']}
      isRequiredSectionTitle={false}
      visibilityTogglerName="enabled"
    >
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isFieldRequired = getFieldValue(['breadCrumb', 'enabled']);
          return (
            <>
              <Form.Item
                name={['breadCrumb', 'link']}
                rules={[
                  {
                    required: isFieldRequired,
                    message: 'Breadcrumb link is required',
                    whitespace: true,
                  },
                  ...(isFieldRequired
                    ? [
                        {
                          type: 'url' as RuleType,
                          message: 'The "Breadcrumb link" field is invalid',
                        },
                      ]
                    : []),
                ]}
              >
                <DebouncedInputWrapper element={Input} type="url" label="Breadcrumb link" />
              </Form.Item>
              <Alert
                showIcon
                icon={<Icon color="#f48809" name="alert-circle-outline" />}
                type="warning"
                message="Please do not add urls to landing pages controlled via CMS, it might result in unexpected behavior"
              />
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
