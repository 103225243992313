import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import styles from './PlanSelection.module.scss';
import { Form, Icon, Input, Switch, Tooltip } from '@saturn/uikit';
import PlanSelectionBlock from './PlanSelectionBlock';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export type PlanSelectionProps = {
  header?: string;
  productId?: string;
};

const PRODUCTS_WITH_SWITCHOVER = [
  'sg-chubb-singletrip',
  'gb-chubb-single-trip',
  'hk-chubb-comprehensive-travel-insurance',
];

export const PlanSelection: FC<PlanSelectionProps> = ({ header, productId }) => {
  return (
    <CollapsiblePanel header={header || 'Plan selection'} name={['planSelection']} withoutSwitch>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isSwitchoverEnabled = getFieldValue(['planSelection', 'offerSwitchover']);
          return (
            <>
              {productId && PRODUCTS_WITH_SWITCHOVER.includes(productId) && (
                <div className={styles.isSwitchover}>
                  <div className={styles.toggleWrapper}>
                    <p className={styles.label}>Offer Switchover?</p>
                    <Form.Item
                      className={styles.switch}
                      name={['planSelection', 'offerSwitchover']}
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="on" unCheckedChildren="off" />
                    </Form.Item>
                    <Tooltip
                      className={styles.tooltip}
                      title="When enabled, a switch appears, allowing customers to easily toggle between Single Trip and Annual Multi-Trip options and see the corresponding prices"
                    >
                      <Icon size={25} name="question-mark-circle-outline" />
                    </Tooltip>
                  </div>
                  {isSwitchoverEnabled && (
                    <Form.Item
                      name={['planSelection', 'switchoverProductBenefit']}
                      rules={[
                        {
                          max: 100,
                          message: 'Switchover Benefit should be at most 100 characters',
                        },
                      ]}
                    >
                      <DebouncedInputWrapper element={Input} label="Switchover product benefit (optional)" />
                    </Form.Item>
                  )}
                </div>
              )}
              <div className={styles.margins}>
                <CollapsiblePanel
                  header="Product Benefits"
                  name={['planSelection']}
                  visibilityTogglerName="productBenefitsVisible"
                  className={styles.collapse}
                >
                  <ContentBlockContainer
                    name={['planSelection', 'productBenefits']}
                    className={styles.content}
                    title="Benefit"
                    addButtonText="Add benefit"
                    maxItems={3}
                  >
                    {props => <PlanSelectionBlock {...props} />}
                  </ContentBlockContainer>
                </CollapsiblePanel>
                {productId && PRODUCTS_WITH_SWITCHOVER.includes(productId) && isSwitchoverEnabled && (
                  <CollapsiblePanel
                    header="Switchover Product Benefits"
                    name={['planSelection']}
                    visibilityTogglerName="switchoverProductBenefitsVisible"
                    className={styles.collapse}
                  >
                    <ContentBlockContainer
                      name={['planSelection', 'switchoverProductBenefits']}
                      className={styles.content}
                      title="Benefit"
                      addButtonText="Add benefit"
                      maxItems={3}
                    >
                      {props => <PlanSelectionBlock {...props} />}
                    </ContentBlockContainer>
                  </CollapsiblePanel>
                )}
              </div>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
