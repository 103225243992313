import type { FC } from 'react';

import { Form, Input } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, IconsGallery } from 'shared/components';

import { CoveragesSubsection } from './components/CoveragesSubsection';

import styles from './Coverages.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export type CoveragesProps = {
  header?: string;
};

export const Coverages: FC<CoveragesProps> = ({ header }) => {
  const sectionName = ['allYouNeedToKnow', 'subSections', 'coverage'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel header={header || 'Coverages'} name={sectionName} showSectionTitle>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...sectionName, 'visible']);
            return (
              <div className={styles.content}>
                <Form.Item
                  preserve={false}
                  valuePropName="icon"
                  name={[...sectionName, 'thumbnailId']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            required: true,
                            message: 'Thumbnail is required',
                          },
                        ]
                      : []),
                  ]}
                >
                  <IconsGallery />
                </Form.Item>
                <Form.Item
                  name={[...sectionName, 'tabSubtitle']}
                  rules={[
                    ...(isFieldRequired
                      ? [
                          {
                            max: 100,
                            message: 'Tab Subtitle should be at most 255 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Tab Subtitle" />
                </Form.Item>
                <ContentBlockContainer
                  maxItems={10}
                  name={[...sectionName, 'subSections']}
                  title="Coverage Subsection"
                  addButtonText="Add coverage subsection"
                  showChangePositionButton={true}
                >
                  {props => <CoveragesSubsection {...props} required={props.required} section="coverage" />}
                </ContentBlockContainer>
              </div>
            );
          }}
        </Form.Item>
      </CollapsiblePanel>
    </div>
  );
};
