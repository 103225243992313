const CATEGORY_SECTION_TITLES: Record<string, string> = {
  seo: 'SEO',
  info: 'General Info',
  hero: 'Hero Banner',
  quoteWidget: 'Quote Widget',
  productQuoteForms: 'Product Quote Forms',
  callUsBack: 'Call Us Back',
  categoryProducts: 'Insurance Products',
  insurancePartners: 'Insurance Partners',
  whyAmericanExpress: 'Why American Express',
  findOutMoreSection: 'Find Out More',
  findOutMoreTwoSection: 'Find Out More 2',
  disclosuresFooter: 'Footer Disclosures',
  secondaryFooterDisclosure: 'Non-amex Footer Disclosures',
  breadcrumb: 'Breadcrumb',
  video: 'Marketing video',
  quoteNow: 'Quote Now',
};

export default CATEGORY_SECTION_TITLES;
