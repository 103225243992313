import type { FC } from 'react';

import { CollapsiblePanel, ContentBlockContainer } from 'shared/components';

import { StandaloneDocumentsBlock } from './components/StandaloneDocumentsBlock';

import styles from './StandaloneDocuments.module.scss';
import { STANDALONE_DOCUMENTS_ALIGNMENT_SELECT } from 'shared/constants';
import { Form, Option, Select } from '@saturn/uikit';

export const StandaloneDocuments: FC<{ header?: string }> = ({ header }) => {
  const sectionName = ['standaloneDocuments'];
  return (
    <div className={styles.wrapper}>
      <CollapsiblePanel
        header={header || 'Standalone Documents'}
        sectionTitleLabel="Section Title"
        name={sectionName}
        showSectionTitle
      >
        <Form.Item
          name={['standaloneDocuments', 'cardAlignment']}
          initialValue={STANDALONE_DOCUMENTS_ALIGNMENT_SELECT.left.value}
          rules={[
            {
              required: true,
              message: 'Card Alignment is required',
            },
          ]}
        >
          <Select placeholder="Card Alignment" defaultValue={STANDALONE_DOCUMENTS_ALIGNMENT_SELECT.left.value}>
            {Object.values(STANDALONE_DOCUMENTS_ALIGNMENT_SELECT).map(({ text, value }) => (
              <Option key={value} data-key={value} value={value}>
                {text}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <ContentBlockContainer
          className={styles.content}
          maxItems={10}
          name={[...sectionName, 'documents']}
          addButtonText="Add document"
          title="Document"
          showChangePositionButton={true}
        >
          {props => <StandaloneDocumentsBlock {...props} />}
        </ContentBlockContainer>
      </CollapsiblePanel>
    </div>
  );
};
