import { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Checkbox, Form, Icon, ImageUploader, Input, Textarea, Tooltip } from '@saturn/uikit';

import { SubTitle } from 'shared/components';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';

import { fileUrlFormatter } from '../../utils';
import { CollapsiblePanel } from '../CollapsiblePanel/CollapsiblePanel';
import { PageSEOProps } from './model';

import styles from './PageSEO.module.scss';
import { DebouncedInputWrapper } from '../DebounceInputWrapper/DebounceInputWrapper';

export const PageSEO: FC<PageSEOProps> = ({ header }) => {
  const seoSocialFileMeta = ['seo', 'socialMedia', 'fileMetadata'];
  const socialImageInlineBody = useImportedInlineBody(seoSocialFileMeta);

  return (
    <CollapsiblePanel header={header || 'SEO'} name={['seo']} withoutSwitch>
      <div className={styles.content}>
        <SubTitle>General SEO</SubTitle>
        <Form.Item
          name={['seo', 'metaTitle']}
          rules={[
            {
              max: 255,
              message: 'Meta title should be at most 255 characters',
            },
          ]}
        >
          <DebouncedInputWrapper element={Input} label="Meta Title" />
        </Form.Item>
        <Form.Item
          name={['seo', 'metaDescription']}
          rules={[
            {
              max: 500,
              message: 'Meta description should be at most 500 characters',
            },
          ]}
        >
          <DebouncedInputWrapper label="Meta Description" element={Textarea} />
        </Form.Item>
        <Form.Item
          name={['seo', 'metaKeywords']}
          getValueFromEvent={e => (e.target.value ? e.target.value.split(',') || [] : null)}
          rules={[
            {
              validator: (_, value) => {
                if (value?.join(',').length > 255) {
                  return Promise.reject(new Error('Meta keywords should be at most 255 characters'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DebouncedInputWrapper element={Input} label="Meta Keywords" />
        </Form.Item>
        <Form.Item
          name={['seo', 'canonicalUrl']}
          rules={[
            {
              type: 'url',
              message: 'The "Canonical URL" field is invalid',
            },
            {
              max: 255,
              message: 'Meta Canonical URL should be at most 255 characters',
            },
          ]}
        >
          <DebouncedInputWrapper element={Input} type="url" label="Canonical URL" />
        </Form.Item>

        <Form.Item name={['seo', 'metaRobotsNoindex']} valuePropName="checked">
          <Checkbox
            title="Should search engines include this page in search resuts (noindex)?"
            isCheckboxOnLeft={true}
          />
        </Form.Item>
        <Form.Item name={['seo', 'metaRobotsNofollow']} valuePropName="checked">
          <Checkbox title="Should search engines follow links on this page (nofollow)?" isCheckboxOnLeft={true} />
        </Form.Item>
        <Form.Item
          name={['seo', 'metaRobotsCustom']}
          rules={[
            {
              max: 255,
              message: 'Custom value of “robots” meta tag should be at most 255 characters',
            },
          ]}
        >
          <DebouncedInputWrapper
            element={Input}
            label="Custom value of “robots” meta tag"
            addonAfter={
              <Tooltip title="This field will override noindex and nofollow checkboxes above">
                <Icon size={35} name="question-mark-circle-outline" />
              </Tooltip>
            }
          />
        </Form.Item>

        <SubTitle>Social Media</SubTitle>
        <div className={styles.wrapper}>
          <Form.Item name={seoSocialFileMeta}>
            <ImageUploader
              inlineBody={socialImageInlineBody}
              content="Upload OG Image"
              tooltip="The recommended resolution for the OG image is 1200x627 pixels, the size — up to 2MB"
              className={styles.image_container}
              action={uploadFileUrl('seo_image')}
              customRequest={onCustomRequest}
              valueFormatter={fileUrlFormatter}
              style={{
                width: 265,
                height: 225,
              }}
            />
          </Form.Item>
          <div className={styles.inputsWrapper}>
            <Form.Item
              name={['seo', 'socialMedia', 'ogTitle']}
              rules={[
                {
                  max: 255,
                  message: 'OG title should be at most 255 characters',
                },
              ]}
            >
              <DebouncedInputWrapper
                element={Input}
                label="OG Title"
                addonAfter={
                  <Tooltip title="This is the title that will be shown in a social media post when this webpage is shared">
                    <Icon size={35} name="question-mark-circle-outline" />
                  </Tooltip>
                }
              />
            </Form.Item>
            <div className={styles.textArea_wrapper}>
              <Form.Item
                className={styles.formItem}
                name={['seo', 'socialMedia', 'ogDescription']}
                rules={[
                  {
                    max: 500,
                    message: 'OG description should be at most 500 characters',
                  },
                ]}
              >
                <DebouncedInputWrapper element={Textarea} label="OG Description" />
              </Form.Item>
              <Tooltip title="This is the description that will be shown in a social media post when this webpage is shared">
                <Icon color="black" name="question-mark-circle-outline" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </CollapsiblePanel>
  );
};
