import { Fragment } from 'react';
import type { FC } from 'react';

import { Form, Input, Switch, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, ContentBlockContainer, MarkdownTip } from 'shared/components';

import { WhatIsIncludedCard } from './WhatIsIncludedCard';

import styles from './WhatIsIncluded.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

export type WhatIsIncludedProps = {
  header?: string;
};

export const WhatIsIncluded: FC<WhatIsIncludedProps> = ({ header }) => {
  return (
    <CollapsiblePanel header={header || 'What Is Included'} name={['whatIsIncludedSection']} showSectionTitle>
      <Form.Item shouldUpdate>
        {({ getFieldValue }) => {
          const isSectionVisible = getFieldValue(['whatIsIncludedSection', 'visible']);
          const isPolicyWordingUrlDisplayed = getFieldValue(['whatIsIncludedSection', 'isPolicyWordingUrlDisplayed']);
          const isPopUpButtonDisplayed = getFieldValue(['whatIsIncludedSection', 'isPopUpButtonDisplayed']);
          return (
            <>
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>Display a policy wording URL?</p>
                <Form.Item name={['whatIsIncludedSection', 'isPolicyWordingUrlDisplayed']} valuePropName="checked">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </div>
              {isPolicyWordingUrlDisplayed ? (
                <Form.Item
                  name={['whatIsIncludedSection', 'policyWordingUrl']}
                  rules={
                    isSectionVisible
                      ? [
                          {
                            required: true,
                            message: 'URL is required',
                            whitespace: true,
                          },
                          {
                            type: 'url',
                            message: 'URL field is invalid',
                          },
                        ]
                      : []
                  }
                >
                  <DebouncedInputWrapper element={Input} label="Policy wording URL" />
                </Form.Item>
              ) : null}
              <div className={styles.toggleWrapper}>
                <p className={styles.label}>Display a button that opens a pop-up?</p>
                <Form.Item name={['whatIsIncludedSection', 'isPopUpButtonDisplayed']} valuePropName="checked">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </div>
              <MarkdownTip
                content={
                  <>
                    Use the fields below to display a list of benefits with checkboxes or x-marks. To display the list -
                    use a standard markdown list syntax. Follow to{' '}
                    <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
                      https://www.markdownguide.org/basic-syntax/
                    </a>{' '}
                    for instructions
                  </>
                }
              >
                <Form.Item
                  name={['whatIsIncludedSection', 'listOfInclusions']}
                  rules={[
                    {
                      required: isSectionVisible && isPopUpButtonDisplayed,
                      message: 'List of Inclusions is required',
                      whitespace: true,
                    },
                    ...(isSectionVisible && isPopUpButtonDisplayed
                      ? [
                          {
                            max: 10000,
                            message: 'List of Inclusions should be at most 10000 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="List of Inclusions" />
                </Form.Item>
              </MarkdownTip>
              <MarkdownTip
                content={
                  <>
                    Use the fields below to display a list of benefits with checkboxes or x-marks. To display the list -
                    use a standard markdown list syntax. Follow to{' '}
                    <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
                      https://www.markdownguide.org/basic-syntax/
                    </a>{' '}
                    for instructions
                  </>
                }
              >
                <Form.Item
                  name={['whatIsIncludedSection', 'listOfExclusions']}
                  rules={[
                    {
                      required: isSectionVisible && isPopUpButtonDisplayed,
                      message: 'List of Exclusions is required',
                      whitespace: true,
                    },
                    ...(isSectionVisible && isPopUpButtonDisplayed
                      ? [
                          {
                            max: 10000,
                            message: 'List of Exclusions should be at most 10000 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="List of Exclusions" />
                </Form.Item>
              </MarkdownTip>
              <ContentBlockContainer
                className={styles.content}
                name={['whatIsIncludedSection', 'cards']}
                title="Card"
                addButtonText="Add Card"
                showChangePositionButton
                maxItems={4}
                hideDeleteButton
              >
                {props => <WhatIsIncludedCard {...props} />}
              </ContentBlockContainer>
            </>
          );
        }}
      </Form.Item>
    </CollapsiblePanel>
  );
};
