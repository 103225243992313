import { FC } from 'react';

import { uploadFileUrl } from '@saturn/api';
import { Form, Icon, ImageUploader, Input, RuleType, Select, Textarea, Tooltip } from '@saturn/uikit';

import { MarkdownTip, VisibilitySwitcher } from 'shared/components';
import { ContentBlockProps } from 'shared/components/ContentBlockContainer';
import useImportedInlineBody from 'shared/hooks/useImportedInlineBody';
import { onCustomRequest } from 'shared/services/api';
import { ProviderOption, fileUrlFormatter, prepareOptions } from 'shared/utils';

import styles from '../../HeroBanner.module.scss';
import { DebouncedInputWrapper } from 'shared/components/DebounceInputWrapper/DebounceInputWrapper';

type HeroBannerBlockProps = ContentBlockProps & { providers: ProviderOption[] };

export const HeroBannerBlock: FC<HeroBannerBlockProps> = ({ name, required, providers, ...fieldProps }) => {
  const heroBannerInlineBody = useImportedInlineBody([...fieldProps.parentName, name, 'fileMetadata']);

  return (
    <div className={styles.wrapper}>
      <Form.Item
        {...fieldProps}
        name={[name, 'fileMetadata']}
        rules={[
          {
            required,
            message: 'Banner Image is required',
          },
        ]}
      >
        <ImageUploader
          inlineBody={heroBannerInlineBody}
          content="Upload Banner Image"
          tooltip="Recommended dimensions are 950*580px"
          className={styles.image_container}
          valueFormatter={fileUrlFormatter}
          action={uploadFileUrl(`hero_image_${name}`)}
          customRequest={onCustomRequest}
          style={{
            width: 265,
            height: 225,
          }}
        />
      </Form.Item>
      <div className={styles.inputsWrapper}>
        <MarkdownTip>
          <Form.Item
            {...fieldProps}
            name={[name, 'title']}
            rules={[
              {
                required,
                message: 'Banner Title is required',
                whitespace: true,
              },
              ...(required
                ? [
                    {
                      max: 75,
                      message: 'Banner Title should be at most 75 characters',
                    },
                  ]
                : []),
            ]}
          >
            <DebouncedInputWrapper element={Textarea} label="Banner Title" />
          </Form.Item>
        </MarkdownTip>
        <MarkdownTip>
          <div className={styles.textArea_wrapper}>
            <Form.Item
              {...fieldProps}
              name={[name, 'description']}
              className={styles.formItem}
              rules={[
                {
                  required,
                  message: 'Description is required',
                  whitespace: true,
                },
                ...(required
                  ? [
                      {
                        max: 255,
                        message: 'Description should be at most 255 characters',
                      },
                    ]
                  : []),
              ]}
            >
              <DebouncedInputWrapper element={Textarea} label="Description" />
            </Form.Item>
          </div>
        </MarkdownTip>
        <Form.Item
          {...fieldProps}
          name={[name, 'seoAltTag']}
          rules={[
            ...(required
              ? [
                  {
                    max: 255,
                    message: 'Alt Tag for SEO should be at most 255 characters',
                  },
                ]
              : []),
          ]}
        >
          <DebouncedInputWrapper
            element={Input}
            label="Alt Tag for SEO"
            addonAfter={
              <Tooltip title="Write image alternative text as plain text, HTML syntax is not required.">
                <Icon size={35} name="question-mark-circle-outline" />
              </Tooltip>
            }
          />
        </Form.Item>
        <VisibilitySwitcher
          title={'Call to Action Button'}
          name={[name, 'ctaButton', 'visible']}
          fieldProps={{ ...fieldProps }}
        />
        <Form.Item {...fieldProps} shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...fieldProps.parentName, name, 'ctaButton', 'visible']);
            return (
              <>
                <Form.Item
                  {...fieldProps}
                  name={[name, 'ctaButton', 'title']}
                  rules={[
                    {
                      required: isFieldRequired && required,
                      message: 'Title is required',
                      whitespace: true,
                    },
                    ...(isFieldRequired && required
                      ? [
                          {
                            max: 75,
                            message: 'Title should be at most 75 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="Title" />
                </Form.Item>
                <Form.Item
                  {...fieldProps}
                  name={[name, 'ctaButton', 'url']}
                  rules={[
                    {
                      required: isFieldRequired && required,
                      message: 'URL is required',
                      whitespace: true,
                    },
                    ...(isFieldRequired && required
                      ? [
                          {
                            max: 1000,
                            message: 'URL should be at most 1000 characters',
                          },
                          {
                            type: 'url' as RuleType,
                            message: 'URL is invalid',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Input} label="URL" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <VisibilitySwitcher
          title={'Promotional offer'}
          name={[name, 'promotionalOffer', 'visible']}
          fieldProps={{ ...fieldProps }}
        />
        <Form.Item {...fieldProps} shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...fieldProps.parentName, name, 'promotionalOffer', 'visible']);
            return (
              <MarkdownTip>
                <Form.Item
                  {...fieldProps}
                  name={[name, 'promotionalOffer', 'description']}
                  rules={[
                    {
                      required: isFieldRequired && required,
                      message: 'Description is required',
                      whitespace: true,
                    },
                    ...(isFieldRequired && required
                      ? [
                          {
                            min: 3,
                            message: 'Description should be at least 3 characters',
                          },
                          {
                            max: 40,
                            message: 'Description should be at most 40 characters',
                          },
                        ]
                      : []),
                  ]}
                >
                  <DebouncedInputWrapper element={Textarea} label="Description" />
                </Form.Item>
              </MarkdownTip>
            );
          }}
        </Form.Item>
        <VisibilitySwitcher
          title={'Insurance provider watermark'}
          name={[name, 'watermark', 'visible']}
          fieldProps={{ ...fieldProps }}
        />
        <Form.Item {...fieldProps} shouldUpdate>
          {({ getFieldValue }) => {
            const isFieldRequired = getFieldValue([...fieldProps.parentName, name, 'watermark', 'visible']);
            return (
              <Form.Item
                {...fieldProps}
                name={[name, 'watermark', 'providerId']}
                rules={[
                  {
                    required: isFieldRequired && required,
                    message: 'Insurance Provider is required',
                  },
                ]}
              >
                <Select data-key="insurance-provider" placeholder="Insurance Provider">
                  {prepareOptions(providers)}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </div>
    </div>
  );
};
